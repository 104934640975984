import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { CreateSalesDepartmentDto, SalesDepartmentDto, UpdateSalesDepartmentDto } from '@sr/dto';
import { salesDepartmentsApi } from './api-slice';

export const useSalesDepartmentsList = () => {
  const { data: salesDepartments, isLoading, error } = salesDepartmentsApi.useGetSalesDepartmentsListQuery({});
  return {
    collection: salesDepartments ?? [],
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading,
  };
};

export const useSalesDepartment = (id: number) => {
  const { data, isLoading, error } = salesDepartmentsApi.useGetSalesDepartmentQuery(id);
  return {
    item: data,
    error: extractNestJsErrorMessage(error),
    isLoading,
  };
};

export const useDeleteSalesDepartment: () => (department: SalesDepartmentDto) => Promise<void> = () => {
  const showConfirmDialog = useConfirmDialog();
  const [deleteSalesDepartment] = salesDepartmentsApi.useDeleteSalesDepartmentMutation();
  return async (salesDepartment: SalesDepartmentDto): Promise<void> => {
    return showConfirmDialog({
      content: `Удалить отдел продаж: ${salesDepartment.title}?`,
    }).then(() => deleteSalesDepartment(salesDepartment.id).unwrap());
  };
};

export const useUpdateSalesDepartment = () => {
  const [update] = salesDepartmentsApi.useUpdateSalesDepartmentMutation();
  return async (data: UpdateSalesDepartmentDto) => {
    return update(data).unwrap();
  };
};

export const useAddSalesDepartment = () => {
  const [addSalesDepartment] = salesDepartmentsApi.useAddSalesDepartmentMutation();
  return async (data: CreateSalesDepartmentDto) => {
    return addSalesDepartment(data).unwrap();
  };
};
