import { invoiceDataApi } from './details/api';
import { REFETCH_TIME_SPAN, extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { CreateInvoiceDto } from '@sr/dto';
import { invoiceApi } from './api-slice';

export type InvoiceLink =
  { purchaseId: number, companyId?: undefined } |
  { companyId: number, purchaseId?: undefined }

export const useInvoiceDetails = (invoiceId: number) => {
  const { data: invoice, isLoading, error } = invoiceDataApi.useGetInvoiceQuery(invoiceId, { refetchOnMountOrArgChange: REFETCH_TIME_SPAN });

  return {
    invoice: invoice,
    loading: isLoading,
    error: extractNestJsErrorMessage(error)
  };
};

export const useLastClosedInvoice = (companyId: number, id: number) => {
  const { data, isLoading, error } = invoiceApi.useGetLastClosedInvoiceQuery({companyId, id}, { refetchOnMountOrArgChange: REFETCH_TIME_SPAN });

  return {
    data: data,
    loading: isLoading,
    error: extractNestJsErrorMessage(error)
  };
};

export const useAddInvoice = ({ companyId, purchaseId }: InvoiceLink) => {
  const [addInvoice] = invoiceDataApi.useAddInvoiceMutation();
  return async (dto : CreateInvoiceDto)=> {
    if (purchaseId) {
      return addInvoice({ ...dto, purchaseId }).unwrap();
    }
    if (companyId) {
      return  addInvoice({ ...dto, companyId }).unwrap();
    }
    throw new Error('Неправильное использование useAddInvoice хука');
  };
};



