import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { SideMenu } from './side-menu';
import { TopBar } from './top-bar';
import { useDrawerToggle } from './drawer-hook';

type Props = {
  children: React.ReactNode;
};

const MainLayout = (props: Props) => {
  const { open, toggleDrawer } = useDrawerToggle();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <TopBar drawerOpen={open} toggleDrawer={toggleDrawer} />

      <SideMenu open={open} toggleOpen={toggleDrawer} />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 2, mb: 2 }}>
          {props.children}
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
