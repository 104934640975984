import { Autocomplete, TextField } from '@mui/material';
import { fieldToTextField, TextFieldProps } from 'formik-mui';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useCompaniesDropdown } from 'hooks/companies-dropdown.hook';
import { CompanyDto } from '@sr/dto';

export const CompanyPickerField = (props: TextFieldProps & { legalEntityIdFilter?:number }) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label,
    legalEntityIdFilter
  } = props;
  const [nameFilter, setNameFilter] = useState('');
  const [open, setOpen] = useState(false);
  const { items: list, isLoading: fetching } = useCompaniesDropdown(nameFilter, legalEntityIdFilter);
  const [selectedCompany, setSelectedCompany] = useState<CompanyDto | null>(null);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: CompanyDto | null) => {
      setSelectedCompany(newValue);
      setFieldValue(name, newValue?.id);
    },
    [setFieldValue, name, setSelectedCompany]
  );

  const onInput = async (event: ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value);
  };

  useEffect(() => {
    setSelectedCompany(list.find(x => x.id === value) ?? null);
  }, [list, value]);


  const loading = open && list.length === 0 && fetching;
  const {
    legalEntityIdFilter: _,
    ...textFieldProps } = props;

  return (
    <Autocomplete
      value={selectedCompany}
      autoComplete={false}
      options={list}
      //sx={{ mt: 2 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInput={onInput}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.name}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(textFieldProps)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..."/>
      }
    />);
};


export const CompanyMultiPickerField = (props: TextFieldProps & {companyTypeFilter?: number}) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label
  } = props;

  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const { items: list, isLoading: fetching } = useCompaniesDropdown(searchInput, undefined, props.companyTypeFilter);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: CompanyDto[]) => {
      setFieldValue(name, newValue.map(x => x.id));
    },
    [setFieldValue, name]
  );

  const loading = open && list.length === 0 && fetching;


  return (
    <Autocomplete
      autoComplete={false}
      inputValue={searchInput}
      onInputChange={(event, value) => setSearchInput(value)}
      multiple
      options={list}
      //sx={{ mt: 2 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.name}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(props)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..."/>
      }
    />);
};
