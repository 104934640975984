import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Formik, Form } from 'formik';
import { useSnack } from 'shared/ui/snack';
import { userFiredStatusApi } from 'features/user/user-fire-status';
import { formatPersonPruning } from 'utils/person-format-helpers';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { INVOICE_STATUSES } from '@sr/dto';

type DialogProps = {
  open: boolean;
  userId: number;
  onClose: () => void;
};

const FiredDialog = (props: DialogProps) => {
  const { open, onClose, userId } = props;
  const { showError, showSuccess } = useSnack();

  const { data } = userFiredStatusApi.useGetEmploymentStatusQuery(userId, { refetchOnMountOrArgChange: 60 });
  const [updateData, { isLoading: isUpdating }] = userFiredStatusApi.useUpdateUserFiredStatusMutation();
  if (!data?.user) {
    return null;
  }
  if (!data?.supervisor) {
    return null;
  }
  const handleSubmit = async (values: { userId: number }) => {
    try {
      updateData({ userId, data: { isFired: true } })
        .unwrap()
        .then(() => showSuccess('Пользователь успешно уволен'))
        .catch(e => showError('Ошибка изменения статуса пользователя', e));
      showSuccess('Пользователь успешно уволен');
    } catch (error) {
      showError(`Ошибка увольнения пользователя: ${error}`);
    } finally {
      onClose();
    }
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Вы уверены, что хотите уволить <b>
        {formatPersonPruning(data?.user)}</b>?</DialogTitle>
      <DialogContent>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ userId }}
        >
          {({ submitForm, isSubmitting }: any) => (
            <Form>
              <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                <p>Текущие привязки изменятся:</p>
                {data?.leadsCount ? (
                  <p>Лиды (<b>{data.leadsCount}</b> шт.) изменят статус.</p>
                ) : null}

                {data?.eventsCount ? (
                  <p>Дела (<b>{data.eventsCount}</b> шт.) закроются.</p>
                ) : null}

                {data?.companyCount ? (
                  <p>Открепится от <b>{data.companyCount}</b> компаний(и).</p>
                ) : null}

                {data?.invoicesCount ? (
                  <p>
                    Счета ({data.invoices?.map((x, i) => (
                      <span key={x.id}>
                        {i > 0 && ', '}
                        <LinkToInvoice invoice={x}/> ({INVOICE_STATUSES[x.status]})
                      </span>
                    ))} - <b>{data.invoicesCount}</b> шт.) закрепятся за <b>{formatPersonPruning(data?.supervisor)}</b>
                  </p>
                ) : null}
              </Stack>
              <DialogActions>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Подождите...' : 'Уволить'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={onClose}
                >
                  Закрыть
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default FiredDialog;
