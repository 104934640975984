import { Container, TableCell, TableRow } from '@mui/material';
import { INVOICE_STATUS_CLOSED, INVOICE_STATUS_DEBT, INVOICE_STATUS_NEW, INVOICE_STATUS_PAID, INVOICE_STATUS_PARTIAL_PAID, INVOICE_STATUS_WCC_CREATED, ProfitDto, TurnoverDto, ReportSaleManagerDto } from '@sr/dto';
import { ErrorBanner } from 'shared/ui/error-banner';
import { GridLayout } from 'shared/ui/grid-layout';
import PriceSpan from 'shared/ui/price-span';
import { LoadingBanner } from 'shared/ui/loading-banner';

const columns = ['Менеджер','Выписан', 'Оплачен', 'Долг', 'Частично оплачен', 'Закрыт', 'Выставлен акт'];

type Props = {
  collection: ReportSaleManagerDto,
  isLoading: boolean,
  error: string,
}

export const SalesGrid = ({ collection, isLoading, error }: Props ) => {
  if (error)
    return (
      <Container>
        <ErrorBanner errorMessage={error} />
      </Container>
    );
  if (isLoading) {
    return <LoadingBanner />;
  }
  return (
    <>
      <h3>Оборот по счетам</h3>
      <GridLayout
        columns={columns}
        items={collection.turnover}
        isLoading={isLoading}
        error={error}
        footer={TurnoverTotal}
        itemRender={(row) => <TurnoverRow row={row} />}
        noItemsText="Нет счетов за выбранный период" />
      <br />
      <h3>Прибыль по счетам</h3>  
      <GridLayout
        columns={columns}
        items={collection.profit}
        isLoading={isLoading}
        error={error}
        footer={ProfitTotal}
        itemRender={(row) => <ProfitRow row={row} />}
        noItemsText="Нет счетов за выбранный период" />
    </>
    
      
  );

};
const TurnoverRow = ({ row }: { row: TurnoverDto }) => {
  return (
    <TableRow>
      <TableCell align="left">{row.fullName}</TableCell>
      <TableCell align="center"><PriceSpan price={row.INVOICE_STATUS_NEW_SUM} size="small" /> ({row.INVOICE_STATUS_NEW_COUNT} шт.)</TableCell>
      <TableCell align="center"><PriceSpan price={row.INVOICE_STATUS_PAID_SUM} size="small" /> ({row.INVOICE_STATUS_PAID_COUNT} шт.)</TableCell>
      <TableCell align="center"><PriceSpan price={row.INVOICE_STATUS_DEBT_SUM} size="small" /> ({row.INVOICE_STATUS_DEBT_COUNT} шт.)</TableCell>
      <TableCell align="center"><PriceSpan price={row.INVOICE_STATUS_PARTIAL_PAID_SUM} size="small" /> ({row.INVOICE_STATUS_PARTIAL_PAID_COUNT} шт.)</TableCell>
      <TableCell align="center"><PriceSpan price={row.INVOICE_STATUS_CLOSED_SUM} size="small" /> ({row.INVOICE_STATUS_CLOSED_COUNT} шт.)</TableCell>
      <TableCell align="center"><PriceSpan price={row.INVOICE_STATUS_WCC_CREATED_SUM} size="small" /> ({row.INVOICE_STATUS_WCC_CREATED_COUNT} шт.)</TableCell>
    </TableRow>);
};

const TurnoverTotal = (rows: TurnoverDto[]) => {
  return (
    <TableRow>
      <TableCell align="center">
        <b>ИТОГО:</b>
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.INVOICE_STATUS_NEW_SUM) + acc, 0)} size="regular" />
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.INVOICE_STATUS_PAID_SUM) + acc, 0)} size="regular" />
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.INVOICE_STATUS_DEBT_SUM) + acc, 0)} size="regular" />
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.INVOICE_STATUS_PARTIAL_PAID_SUM) + acc, 0)} size="regular" />         
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.INVOICE_STATUS_CLOSED_SUM) + acc, 0)} size="regular" />            
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.INVOICE_STATUS_WCC_CREATED_SUM) + acc, 0)} size="regular" />  
      </TableCell>
    </TableRow>

  );
};

const ProfitRow = ({ row }: { row: ProfitDto }) => {
  return (
    <TableRow>
      <TableCell align="left">{row.fullName}</TableCell>
      <TableCell align="center"><PriceSpan price={row.profitByStatus[INVOICE_STATUS_NEW]} size="small" /></TableCell>
      <TableCell align="center"><PriceSpan price={row.profitByStatus[INVOICE_STATUS_PAID]} size="small" /></TableCell>
      <TableCell align="center"><PriceSpan price={row.profitByStatus[INVOICE_STATUS_DEBT]} size="small" /></TableCell>
      <TableCell align="center"><PriceSpan price={row.profitByStatus[INVOICE_STATUS_PARTIAL_PAID]} size="small" /></TableCell>
      <TableCell align="center"><PriceSpan price={row.profitByStatus[INVOICE_STATUS_CLOSED]} size="small" /></TableCell>
      <TableCell align="center"><PriceSpan price={row.profitByStatus[INVOICE_STATUS_WCC_CREATED]} size="small" /></TableCell>
    </TableRow>);
};

const ProfitTotal = (rows: ProfitDto[]) => {
  return (
    <TableRow>
      <TableCell align="center">
        <b>ИТОГО:</b>
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.profitByStatus[INVOICE_STATUS_NEW]) + acc, 0)} size="regular" />
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.profitByStatus[INVOICE_STATUS_PAID]) + acc, 0)} size="regular" />
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.profitByStatus[INVOICE_STATUS_DEBT]) + acc, 0)} size="regular" />
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.profitByStatus[INVOICE_STATUS_PARTIAL_PAID]) + acc, 0)} size="regular" />         
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.profitByStatus[INVOICE_STATUS_CLOSED]) + acc, 0)} size="regular" />            
      </TableCell>
      <TableCell align="center">
        <PriceSpan price={rows.reduce((acc, curr ) => (curr.profitByStatus[INVOICE_STATUS_WCC_CREATED]) + acc, 0)} size="regular" />  
      </TableCell>
    </TableRow>
  );
};
