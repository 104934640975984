type READY = 1;
type SEND = 2;
type UPLOADED = 3;
type CONFIRMED = 4;
type ORIGINAL = 5;

export const CONTRACT_STATUS_READY = 1;
export const CONTRACT_STATUS_SEND = 2;
export const CONTRACT_STATUS_UPLOADED = 3;
export const CONTRACT_STATUS_CONFIRMED = 4;
export const CONTRACT_STATUS_ORIGINAL = 5;

export type ContractStatus = READY | SEND | UPLOADED | CONFIRMED | ORIGINAL;
export type ContractStatusSupplier = READY | UPLOADED | CONFIRMED | ORIGINAL;

export const CONTRACT_STATUSES: { [key in ContractStatus]: string } = {
  [CONTRACT_STATUS_READY]: 'Готов',
  [CONTRACT_STATUS_SEND]: 'Отправлен',
  [CONTRACT_STATUS_UPLOADED]: 'Загружен скан',
  [CONTRACT_STATUS_CONFIRMED]: 'Подтвержден скан',
  [CONTRACT_STATUS_ORIGINAL]: 'Получен оригинал',
};

export const CONTRACT_STATUSES_KEYS = Object.keys(CONTRACT_STATUSES).map((k) => Number(k) as ContractStatus);

// Type definitions for client/supplier
export type CLIENT = 1;
export type SUPPLIER = 2;
export const CONTRACT_TYPE_CLIENT: CLIENT = 1;
export const CONTRACT_TYPE_SUPPLIER: SUPPLIER = 2;
export type ContractType = CLIENT | SUPPLIER;

// Function to get the appropriate statuses based on type
export const getContractStatuses = (type: ContractType) => {
  if (type === CONTRACT_TYPE_SUPPLIER) {
    // Filter out status 2 for suppliers
    const supplierStatuses: { [key in ContractStatusSupplier]: string } = {} as any;

    Object.entries(CONTRACT_STATUSES).forEach(([key, value]) => {
      const numKey = Number(key) as ContractStatus;
      if (numKey !== 2) {
        supplierStatuses[numKey as ContractStatusSupplier] = value;
      }
    });

    return supplierStatuses;
  }

  return CONTRACT_STATUSES;
};

export const getContractStatusKeys = (type: ContractType) => {
  const statuses = getContractStatuses(type);
  return Object.keys(statuses).map((k) => Number(k) as ContractStatus);
};
