import { Stack, Button, Paper, List, ListItem } from '@mui/material';
import { Box } from '@mui/system';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { CardPaper } from 'shared/ui/card-paper';
import { ErrorBanner } from 'shared/ui/error-banner';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { useSnack } from 'shared/ui/snack';
import { FormValues, initialValuesFromDto, toDto, validate } from './form';
import { UserPickerField } from '../user-picker';
import { useUpdateUserCompanyData, useUserCompanyDetails } from './user-company-data.hook';
import Typography from '@mui/material/Typography';
import { LinkToUser } from 'shared/ui/links/link-to-user';
import { SalesDepartmentPicker } from '../../../shared/ui/formik-fields/sales-department-picker';

export const UserCompanyData = ({ userId }: { userId: number }) => {
  const { userCompanyData, isLoading, error } = useUserCompanyDetails(userId);
  const updateData = useUpdateUserCompanyData(userId);

  const { showSuccess, showError } = useSnack();

  if (isLoading) return <LoadingBanner />;

  if (error) return <ErrorBanner errorMessage={extractNestJsErrorMessage(error)} />;

  const handelSubmit = async (formValues: FormValues) => {
    return updateData(toDto(formValues))
      .then(() => showSuccess('Данные успешно обновлены'))
      .catch((e) => showError('Ошибка обновления данных', e));
  };

  const initialValues = initialValuesFromDto(userCompanyData);
  const supervisedUsers = userCompanyData?.supervisedUsers || [];

  return (
    <CardPaper>
      <Paper elevation={3} sx={{ p: 2, my: 3 }}>
        <Typography variant="h6" gutterBottom>
          Подчиненные
        </Typography>
        <List>
          {supervisedUsers.length === 0 ? (
            <ListItem>
              <Typography variant="body1" color="textSecondary">
                Нет подчиненных
              </Typography>
            </ListItem>
          ) : (
            supervisedUsers.map((user) => (
              <ListItem key={user.id} divider>
                <LinkToUser user={user} />
              </ListItem>
            ))
          )}
        </List>
      </Paper>
      <Box mt={1}>
        <Formik initialValues={initialValues} onSubmit={handelSubmit} validate={validate} enableReinitialize>
          {({ submitForm, dirty, isValid }) => (
            <>
              <Stack direction="column" spacing={2}>
                <Stack direction="column" spacing={2}>
                  <Field name="position" label="Должность" fullWidth component={TextField} />
                  <Field name="salesDepartmentId" label="Отдел продаж" fullWidth component={SalesDepartmentPicker} />
                  <Field name="baseSalary" label="Оклад" fullWidth component={TextField} />
                  <Field name="salesPlan" label="План" fullWidth component={TextField} />
                  <Field name="supervisorId" label="Руководитель" fullWidth initialId={initialValues.supervisorId} component={UserPickerField} />
                  <Field name="bonusFundRate" label="Ставка премиального фонда" fillWidth component={TextField} />
                  <Field
                    name="defaultManagerPercent"
                    label="Базовый процент по сделкам"
                    fullWidth
                    component={TextField} />
                </Stack>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="contained" onClick={submitForm} disabled={!dirty || !isValid}>
                    сохранить
                  </Button>
                </Box>
              </Stack>
            </>
          )}
        </Formik>
      </Box>
    </CardPaper>
  );
};
