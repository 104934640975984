import { MouseEvent } from 'react';
import MenuParams from 'shared/ui/menu-params';
import { TableRow, TableCell, IconButton, Chip, Button, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'shared/ui/link-base';
import { CreateRoleDto, RoleDto, UpdateRoleDto } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { useCreateOrEditDialog } from '../../../hooks/create-or-edit-dialog.hook';
import { PopupFormData, formTools } from './user-roles-create-edit.form';
import { NotificationsConfig, useSnack } from '../../../shared/ui/snack';
import AddIcon from '@mui/icons-material/Add';
import { FormDialog } from '../../../shared/ui/form-dialog';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useAddUserRole, useDeleteUserRole, useUpdateUserRole, useUserRolesList } from './user-roles.hooks';
import { PermissionPicker } from './permission-picker/permission-picker';
import { roleLinkManager } from '../../../utils/link-manager';

const columns = ['Название роли', 'Доступные пермисии', ''];

export const UsersRolesList = () => {
  const { collection: rolesList, isLoading, error } = useUserRolesList();
  const addItem = useAddUserRole();
  const updateItem = useUpdateUserRole();
  const removeItem = useDeleteUserRole();

  const notificationsConfig: NotificationsConfig = {
    itemTitleForm1: 'роль пользователя',
    itemTitleForm2: 'пермисии',
    gender: 'female',
  };

  const { showFailedDelete } = useSnack(notificationsConfig);
  const { dialogProps, openToAdd, openToEdit, itemToEdit } = useCreateOrEditDialog<RoleDto, CreateRoleDto, UpdateRoleDto, PopupFormData>({
    addHandler: async (dto) => {
      await addItem(dto);
    },
    updateHandler: async (dto) => {
      await updateItem(dto);
    },
    formTools: formTools,
    notificationsConfig,
  });

  const { openMenu, menuData } = useMenu<RoleDto>({
    editHandler: openToEdit,
    deleteHandler: async (dto) => {
      try {
        await removeItem(dto);
      } catch (err) {
        showFailedDelete(err as Error);
      }
    },
  });

  return (
    <>
      <Button variant="contained" sx={{ mb: 2 }} onClick={() => openToAdd()} startIcon={<AddIcon />}>
        Добавить
      </Button>
      <GridLayout
        columns={columns}
        items={rolesList}
        isLoading={isLoading}
        error={error}
        totalItemsCount={rolesList.length}
        noItemsText="Список ролей пуст"
        itemRender={(i) => <Row key={i.id} role={i} onMenuClick={openMenu} />}
      />
      <FormDialog<PopupFormData>
        {...dialogProps}
        renderTitle={!itemToEdit ? 'Новая роль пользователя' : 'Роль пользователя'}
        renderForm={(props) => (
          <Stack sx={{ width: '500px' }}>
            <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
              <Field {...props} required name="title" component={TextField} label="Название роли" fullWidth />
              <Field {...props} required name="permissions" component={PermissionPicker} fullWidth />
            </Stack>
          </Stack>
        )}
      />
      <RowMenu menuData={menuData} />
    </>
  );
};

type RowProps = {
  role: RoleDto;
  onMenuClick: (menuParams: MenuParams<RoleDto>) => void;
};

export const Row = ({ role, onMenuClick }: RowProps) => {
  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: role });
  };

  return (
    <>
      <TableRow key={role.id}>
        <TableCell>
          <Link to={roleLinkManager.link(role.id)}>{role.title}</Link>
        </TableCell>
        {role.permissions && (
          <TableCell>
            {role.permissions.map((item) => (
              <Chip key={item.id} label={item.title} sx={{ mx: 1, mb: 1 }} />
            ))}
          </TableCell>
        )}
        <TableCell align="right">
          <IconButton edge="end" onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
