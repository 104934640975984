import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { companiesApi } from 'entities/company/api.slice';

export const useCompanyEmployee = (companyId: number) => {
  const { data, isLoading, error } = companiesApi.useGetCompanyEmployeesQuery(companyId, { refetchOnMountOrArgChange: 120 });

  return {
    collection: data ?? [],
    error: extractNestJsErrorMessage(error),
    isLoading,
  };
};
