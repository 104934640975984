import { createApi } from '@reduxjs/toolkit/query/react';
import { INVOICE_REPORT_BASE_URL } from 'shared/api/api';
import { createBaseQuery } from 'shared/api/rtk-query';
import { InvoiceReportDto } from '@sr/dto';

export const invoiceReportApi = createApi({
  reducerPath: 'invoice-report',
  baseQuery: createBaseQuery(INVOICE_REPORT_BASE_URL),
  endpoints: (builder) => ({
    getInvoiceRows: builder.query<InvoiceReportDto, { invoiceId: number }>({
      query: ({ invoiceId }) => ({
        url: `rows/${invoiceId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { invoiceId }) => [{ type: 'invoice-report-rows', id: invoiceId }],
    }),
  }),
  tagTypes: ['invoice-report-rows'],
});
