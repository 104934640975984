import React from 'react';
import { ExpandMore, ExpandLess, Expand } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import ListItemLink from 'components/Mui/list-item-link';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Collapse, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Box } from '@mui/material';
import { useLocalStorageState } from 'shared/hooks/local-storage-state';
import { Drawer } from 'shared/ui/drawer';
import { menuItems, MenuItemType } from './menu-items';

type Props = {
  open: boolean;
  toggleOpen: () => void;
};

export const SideMenu = ({ open, toggleOpen }: Props) => {
  const [allSectionsOpen, setAllSectionsOpen] = useLocalStorageState('all-menu-open', true);
  const [openSections, setOpenSections] = useLocalStorageState<{ [key: string]: boolean }>('sections', {});
  const location = useLocation();

  const toggleSection = (title: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  const toggleAllSections = () => {
    const newState = !allSectionsOpen;
    setAllSectionsOpen(newState);

    const updateOpenState = (items: MenuItemType[]) => {
      return items.reduce(
        (acc, item) => {
          acc[item.title] = newState;
          if (item.subItems) {
            Object.assign(acc, updateOpenState(item.subItems));
          }
          return acc;
        },
        {} as Record<string, boolean>,
      );
    };
    const updatedSections = updateOpenState(menuItems);
    setOpenSections(updatedSections);
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const renderMenuItems = (items: MenuItemType[], depth = 0) => {
    return items.map((item) => (
      <React.Fragment key={item.title}>
        {item.subItems ? (
          <>
            <ListItemButton
              onClick={() => toggleSection(item.title)}
              sx={{
                pl: open ? `${depth * 12}px` : 2,
                backgroundColor: isActive(item.to ?? '') ? 'rgba(18,130,220,0.2)' : 'transparent',
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: isActive(item.to ?? '') ? 700 : 400,
                  },
                }}
              />
              {openSections[item.title] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openSections[item.title]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenuItems(item.subItems, depth + 1)}
              </List>
            </Collapse>
          </>
        ) : (
          <ListItemLink
            to={item.to as string}
            primary={item.title}
            icon={item.icon as React.ReactElement}
            sx={{
              backgroundColor: isActive(item.to ?? '') ? 'rgba(18,130,220,0.2)' : 'transparent',
              pl: open ? `${depth * 12}px` : 2,
              '& .MuiListItemText-primary': {
                fontWeight: isActive(item.to ?? '') ? 700 : 400,
              },
            }}
          />
        )}
      </React.Fragment>
    ));
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden', // Prevent drawer paper from scrolling
        },
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleOpen}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton onClick={toggleAllSections}>
          <Expand />
        </IconButton>
      </Toolbar>
      <Divider />
      <Box
        sx={{
          overflow: 'auto',
          flex: 1,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none', // IE and Edge
          scrollbarWidth: 'none', // Firefox
        }}
      >
        <List component="nav">{renderMenuItems(menuItems, 1)}</List>
      </Box>
    </Drawer>
  );
};
