import { BaseUrlParamsFilter, BaseUrlParamsFilterProps } from '../../../entities/filters/base-url-params-filter';
import { Field } from 'formik';
import { LeadStatusPickerField } from '../../../shared/ui/formik-fields/lead-status-picker-field';

export const LeadStatusParamFilter = ({
  queryParamName,
  label,
  size = 'medium',
  multi = false,
  customSize,
}: BaseUrlParamsFilterProps & { multi?: boolean; customSize?: number }) => {
  return (
    <BaseUrlParamsFilter queryParamName={queryParamName}>
      <Field multi={multi} fullWidth name={queryParamName} label={label} clearable={true} size={size} component={LeadStatusPickerField} customSize={customSize} />
    </BaseUrlParamsFilter>
  );
};
