import { Box } from '@mui/material';
import { useTitleHook } from 'hooks/page-title.hook';
import { PageHeader } from 'shared/ui/details-page-header';
import { ContractStatusStepper } from 'components/Contracts/contract-status-stepper';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { ErrorBanner } from 'shared/ui/error-banner';
import { useSnack } from 'shared/ui/snack';
import { formTools, FormValues } from 'components/ContractAppendix/contract-appendix.form-tools';
import { ContractAppendixBasicsCard } from 'components/ContractAppendix/contract-appendix-basic-card';
import { useIdParam } from 'utils/url-paramters.hook';
import { useContractAppendix } from 'entities/contract-appendix/lib';
import { PrintAppendixButton } from 'entities/contract-appendix/print-form';

export const ContractAppendixDetailsPage = () => {
  const appendixId = useIdParam('appendixId');

  const { item: appendix, error, updateItem } = useContractAppendix(appendixId);

  const appendixNumber = `№ ${appendix?.appendixNumber}`;
  const contractNumner = `№ ${appendix?.contract.id}`;
  useTitleHook(`Приложение ${appendixNumber} к договору ${contractNumner}`);

  const { showSuccess, showError } = useSnack();

  const handleUpdate = (formData: FormValues) => {
    const dto = formTools.toUpdateDto(appendix!.id, formData);
    return updateItem(dto)
      .then(() => showSuccess('Данные приложения обновлены'))
      .catch((e) => showError(`Ошибка обновления данных приложения: ${e}`));
  };

  if (!appendix)
    if (error) return <ErrorBanner errorMessage={error} />;
    else return <LoadingBanner />;

  return (
    <>
      <PageHeader
        title={
          <PageHeader.Text>
            Приложение <PageHeader.Text.Stressed>{appendixNumber} </PageHeader.Text.Stressed>к договору <PageHeader.Text.Stressed>{contractNumner}</PageHeader.Text.Stressed>
          </PageHeader.Text>
        }
        actions={<PrintAppendixButton appendixId={appendixId} />}
        subHeader={
          <Box maxWidth={800}>
            <ContractStatusStepper status={appendix?.status} contractType={appendix?.contract.type} />
          </Box>
        }
      />

      <ContractAppendixBasicsCard appenidx={appendix} update={handleUpdate} />

      {/* <Stack direction="row" spacing={2}>
        <Box>
        </Box>
        <Box>
          <PartiesCard appendix={appendix} />
        </Box>
      </Stack> */}
    </>
  );
};

// const PartiesCard = ({ appendix }: { appendix: ContractAppendixDto }) => {
//   return (
//     <CardPaper>
//       <

//     </CardPaper>
//   )
// }

export default ContractAppendixDetailsPage;
