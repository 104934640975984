import { ReactNode } from 'react';
import { ButtonLink, Link } from 'shared/ui/link-base';
import { ContractDto, ContractInfoDto } from '@sr/dto';
import { ButtonProps } from '@mui/material';
import { CONTRACT_STATUS_STYLE } from 'entities/contract/contract-status-span';
import { contractLinkManager } from 'utils/link-manager';

type Props = { contract: ContractDto | ContractInfoDto, children?: ReactNode, caption?: string }

export const LinkToContract = ({ contract: { id, name, status }, children, caption }: Props) => {

  const link = contractLinkManager.link(id);

  const buttonProps: ButtonProps = {
    size: 'small',
    variant: CONTRACT_STATUS_STYLE[status].buttonVariant,
    color: CONTRACT_STATUS_STYLE[status].buttonColor,
    sx: { whiteSpace: 'nowrap' },
  }

  if (caption)
    return (
      <ButtonLink buttonProps={buttonProps} to={link}>
        {caption}
      </ButtonLink>
    );

  if (children)
    return (
      <Link to={link}     >
        {children}
      </Link>
    )

  return (
    <ButtonLink buttonProps={buttonProps} to={link}>
      №{id} {name && <>({name})</>}
    </ButtonLink>
  );
};
