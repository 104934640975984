import { UpdateUserCompanyDataDto, UserCompanyDataDto } from '@sr/dto';
import { userApi } from 'entities/user/api';
import { salesDepartmentsApi } from '../../../entities/sales-departments/api-slice';

const injectedUserApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyData: builder.query<UserCompanyDataDto, number>({
      query: (userId: number) => `users/${userId}/company-data`,
    }),
    updateCompanyData: builder.mutation<UserCompanyDataDto, { userId: number; data: UpdateUserCompanyDataDto }>({
      query: ({ userId, data }) => ({
        url: `users/${userId}/company-data`,
        body: data,
        method: 'PUT',
      }),
      async onQueryStarted({ userId, data }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          injectedUserApi.util.updateQueryData('getCompanyData', userId, (draft) => {
            Object.assign(draft, data);
          }),
        );
        try {
          await queryFulfilled;
          dispatch(salesDepartmentsApi.util.invalidateTags([{ type: 'sales-departments' }]));
        } catch {
          dispatch(userCompanyDataApi.util.invalidateTags([{ type: 'user-company-data', id: userId }]));
          patchResult.undo();
        }
      },
    }),
  }),
});

export const userCompanyDataApi = injectedUserApi.enhanceEndpoints({
  addTagTypes: ['user-company-data'],
  endpoints: {
    getCompanyData: {
      providesTags: (result, error, query) => (result ? [{ type: 'user-company-data', id: query }] : ['user-company-data']),
    },
  },
});
