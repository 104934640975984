import { createApi } from '@reduxjs/toolkit/query/react';
import { SearchResultsDto } from '@sr/dto';
import { createBaseQuery } from 'shared/api/rtk-query';

const SEARCH_BASE_PATH = 'search';

export const searchApi = createApi({
  reducerPath: 'search-results',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    getSearchResults: builder.query<SearchResultsDto, string>({
      query: (query) => ({
        url: `${SEARCH_BASE_PATH}?${query}`,
        method: 'GET',
      }),
      providesTags: ['*'],
    }),
  }),
  tagTypes: ['*'],
});
