import { IPerson } from '@sr/dto';

export const formatPerson = (person: Pick<IPerson, 'firstName' | 'secondName' | 'middleName'>): string => {
  const { firstName, secondName, middleName } = person;
  //return `${firstName} ${middleName ? middleName : ''} ${secondName ? secondName : ''}`;
  return `${firstName} ${secondName ? secondName : ''}`;
}

export const formatPersonPruning= (person: Pick<IPerson, 'firstName' | 'secondName' | 'middleName'>): string => {
  const { firstName, secondName, middleName } = person;
  return `${secondName ? secondName : ''} ${firstName ? firstName.charAt(0).toUpperCase()+'.' : ''} ${middleName ? middleName.charAt(0).toUpperCase()+'.' : ''}`;
}