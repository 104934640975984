import { CardPaperTitled } from 'shared/ui/card-paper';
import { ContactsPanel } from 'widgets/contacts/contact-panel';
import { EntitiesWithContacts } from 'features/contact/contacts.types';
import { useMemo } from 'react';
import { useCompanyEmployee } from 'entities/company/company-persons.hook';

const CompanyContactsCard = ({ companyId }: { companyId: number; }) => {
  const { collection } = useCompanyEmployee(companyId);
  const excludePersonIds = useMemo(() => 
    collection ? collection.flatMap(item => item.id) : [], 
  [collection]);
  return (
    <CardPaperTitled title="Контакты">
      <ContactsPanel relationType={EntitiesWithContacts.company} relationId={companyId} dupesDisplayOptions={{ excludeLists: { person: excludePersonIds } }}/>
    </CardPaperTitled>
  );
};

export default CompanyContactsCard;