import { IPagedCollection, IPagingData, MultipartData } from '@sr/dto';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { plainToInstance } from 'class-transformer';
import { pagingDataToParams } from 'utils/remote-paged-collection.hook';
import { APP_BASE_URL } from './api';
import { clearAuthData, getAccessToken } from './token';

const defaultHeaders = { 'Content-Type': 'application/json' };

export type ErrorData = {
  message: string;
};

const api = axios.create({ baseURL: APP_BASE_URL });

// Interceptor for 401 error. Ignored in publicRequest
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const isSkipping = error.config.skipUnauthorizedRedirectInterceptor && error.config.skipUnauthorizedRedirectInterceptor !== true;
    console.log(error.config);
    if (error.response.status === 401 && isSkipping) {
      clearAuthData();
      window.location.reload();
    }
    throw new Error(error.response.data.message || error.response.data);
  },
);

// api.interceptors.response.use(
//   (config) => {
//     return config;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//     if (
//       error.response.status == 401 &&
//       error.config &&
//       !error.config._isRetry
//     ) {
//       originalRequest._isRetry = true;
//       try {
//         const refreshToken = getRefreshToken();
//         if (!refreshToken) throw new Error('Unauthorized');

//         const response = await fetchRefresh(refreshToken);
//         // const response = await axios.get<LoginResponse>(`${API_URL}/refresh`, {
//         //   withCredentials: true,
//         // });
//         setAccessToken(response.accessToken);
//         setRefreshToken(response.refreshToken);
//         return api.request(originalRequest);
//       } catch (e) {
//         console.log("НЕ АВТОРИЗОВАН");
//       }
//     }
//     throw error;
//   }
// );

export function toFormData(data: any): MultipartData {
  const result: MultipartData = {};
  const keys = Object.keys(data);
  for (const k of keys) {
    if (Array.isArray(data[k]) && data[k].every((entry: unknown) => entry instanceof File)) {
      result[k] = data[k];
      continue;
    }
    if (data[k] !== undefined) result[k] = data[k] !== null ? (data[k] instanceof File ? data[k] : data[k].toString()) : null;
  }
  return result;
}

function request<TReqData, TRespData>(
  endpoint: string,
  method: Method,
  data?: TReqData,
  headers?: Record<string, string> | undefined,
  params?: { [key: string]: string },
  config?: { skipUnauthorizedRedirectInterceptor?: boolean },
): Promise<TRespData> {
  headers = { ...defaultHeaders, ...headers };

  const req: AxiosRequestConfig = {
    // baseURL: baseURL,
    url: endpoint,
    method: method,
    headers: headers,
    params: params,
    data: data,
    ...config,
  };

  const response = api
    .request<TReqData, AxiosResponse<TRespData, TReqData>>(req)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        throw new Error(error.response.data.message || error.response.data);
      }
      throw error;
    });

  return response;
}

export async function publicRequest<TReqData, TRespData>(endpoint: string, method: Method, data?: TReqData): Promise<TRespData> {
  return request(endpoint, method, data, undefined, undefined, { skipUnauthorizedRedirectInterceptor: true });
}

export async function authorizedRequest<TReqData, TRespData>(
  endpoint: string,
  method: Method,
  data?: TReqData,
  headers?: Record<string, string> | undefined,
  params?: { [key: string]: string },
): Promise<TRespData> {
  const token = getAccessToken();
  headers = { ...defaultHeaders, ...headers };
  headers = token ? { ...headers, ...{ Authorization: 'Bearer ' + token } } : headers;

  return request(endpoint, method, data, headers, params);
}

export async function authorizedFormDataRequest<TResp>(endpoint: string, method: Method, data: MultipartData) {
  const formData = new FormData();

  for (var key in data) {
    if (!Array.from(formData.keys()).includes(key)) {
      if (Array.isArray(data[key])) {
        const files: Blob[] = data[key] as Blob[];

        files.forEach((file) => {
          formData.append(key, file);
        });
        // formData.append(key, data[key]);
      } else {
        const dataItem = data[key] as Blob | string;
        formData.append(key, dataItem);
      }
    }
  }

  return authorizedRequest<FormData, TResp>(endpoint, method, formData, { 'Content-Type': 'multipart/form-data' });
}

// где может отвалиться:
// - на самом фече, если нет сети
// - самому кинуть если статус не ОК
// - парсинг тела ответа в JSON

// 200: тело валидно - все хорошо
// 200: тело - не известно
// 200: тело - не валидно, на сервере изменился формат
// 401 например: тело - точно валидно, мы с сервера кидаем это осознанно
// 500: тело - может быть не валидным, не известно по какой причине мы получаем эту ошибку
// а вот нету интернета, сервер даже статус вернуть не может - как быть?

type ReqProps<TReq, TResp = void> = {
  endpoint: string;
  method: Method;
  responseConstructor?: { new (): TResp };
  data?: TReq;
  headers?: Record<string, string>;
};

export async function authReq<TReq, TResp>(props: ReqProps<TReq, TResp>) {
  const response = await authorizedRequest(props.endpoint, props.method, props.data, props.headers);

  return props.responseConstructor && plainToInstance(props.responseConstructor, response, { enableImplicitConversion: true });
}

export async function fetchReq<TResp>(endPoint: string, responseConstructor?: { new (): TResp }) {
  const response = await authorizedRequest<void, TResp>(endPoint, 'GET');

  return responseConstructor ? plainToInstance(responseConstructor, response, { enableImplicitConversion: true }) : response;
}

export async function fetchCollectionReq<TResp>(endPoint: string, responseConstructor: { new (): TResp }, params?: {}) {
  const response = await authorizedRequest<void, TResp[]>(endPoint, 'GET', undefined, undefined, params);

  return plainToInstance(responseConstructor, response, { enableImplicitConversion: true });
}

export async function fetchPagedReq<TReqData, TRespItemData>(
  endpoint: string,
  pagingData: IPagingData,
  responseConstructor?: { new (): TRespItemData },
  params?: {},
): Promise<IPagedCollection<TRespItemData>> {
  const res = await authorizedRequest<TReqData, IPagedCollection<TRespItemData>>(endpoint, 'GET', undefined, undefined, {
    ...params,
    ...pagingDataToParams(pagingData),
  });

  if (responseConstructor !== undefined) res.items = plainToInstance(responseConstructor, res.items, { enableImplicitConversion: true });

  return res;
}

export async function postReq<TReq, TResp>(endPoint: string, data: TReq, responseConstructor: { new (): TResp }) {
  const response = await authorizedRequest<TReq, TResp>(endPoint, 'POST', data);

  return plainToInstance(responseConstructor, response, { enableImplicitConversion: true });
}

export async function putReq<TReq, TResp>(endPoint: string, data: TReq, responseConstructor?: { new (): TResp }) {
  const response = await authorizedRequest<TReq, TResp>(endPoint, 'PUT', data);

  return responseConstructor ? plainToInstance(responseConstructor, response, { enableImplicitConversion: true }) : response;
}

export async function delReq<TReq = void>(endPoint: string, id: number) {
  await authorizedRequest<TReq, void>(`${endPoint}/${id}`, 'DELETE');
}

// export async function fetchCollection<TResp>(
//   endPoint: string,
//   responseConstructor: { new(): TResp }
// ) {
//   const reaponse = authorizedRequest<FormData, TResp>(
//     endPoint,
//     'GET',
//   );

//   return plainToInstance(responseConstructor, reaponse, { enableImplicitConversion: true });
// }
