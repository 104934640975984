import { createBaseQuery } from 'shared/api/rtk-query';
import { DownloadFileDto, ParsedEmailsDto } from '@sr/dto';
import { createApi } from '@reduxjs/toolkit/query/react';

export const parsedEmailApi = createApi({
  reducerPath: 'parsedEmailApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['*'],
  endpoints: (builder) => {
    return {
      getParsedEmail: builder.query<ParsedEmailsDto, number>({
        query: (id: number) => ({
          url: `email-parser/${id}`,
          method: 'GET',
        }),
        providesTags: ['*'],
      }),
      getEmailAttachments: builder.query<DownloadFileDto[], number[]>({
        query: (ids: number[]) => ({
          url: `files?ids=${ids}`,
          method: 'GET',
        }),
        providesTags: ['*'],
      }),
    };
  },
});
