import { createApi } from '@reduxjs/toolkit/query/react';
import { PURCHASE_BASE_URL } from 'shared/api/api';
import { createBaseQuery } from 'shared/api/rtk-query';
import { PurchaseDto, UpdatePurchaseStatusDto } from '@sr/dto';
import { UpdatePurchaseClientDto } from './purchase.hook';
import { toRtkFormData } from 'utils/to-form-data';

export const purchaseApi = createApi({
  reducerPath: 'purchases',
  baseQuery: createBaseQuery(PURCHASE_BASE_URL),
  endpoints: (builder) => ({
    getPurchase: builder.query<PurchaseDto, number>({
      query: (id) => ({
        url: `${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, query) => [{ type: 'purchases', id: query }],
    }),
    updatePurchase: builder.mutation<PurchaseDto, UpdatePurchaseClientDto>({
      query: (dto) => ({
        url: '/update',
        method: 'POST',
        body: toRtkFormData<UpdatePurchaseClientDto>(dto),
        formData: true,
      }),
      invalidatesTags: (res, error, query) => [{ type: 'purchases', id: query.id }],
    }),
    updatePurchaseStatus: builder.mutation<PurchaseDto, UpdatePurchaseStatusDto>({
      query: ({ ...dto }) => ({
        url: '/status',
        body: dto,
        method: 'PUT',
      }),
      invalidatesTags: (res, error, query) => [{ type: 'purchases', id: query.id }],
    }),
  }),
  tagTypes: ['purchases'],
});
