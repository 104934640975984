import { Box, Stack, Avatar, Paper, Typography } from '@mui/material';
import { CommentDto } from '@sr/dto';
import { formatDate } from 'utils/date-format-helpers';
import { formatPerson } from 'utils/person-format-helpers';


export const CommentItem = ({ comment }: { comment: CommentDto }) => {
  return (
    <Box>
      <Stack direction='row'>
        {comment.user &&
          <Box marginRight={2}>
            <Avatar></Avatar>
          </Box>
        }
        <Box width='100%'>
          <Paper variant='outlined' sx={{
            backgroundColor: 'grey.100',
            p: 1
          }} >
            <Stack direction='column' spacing={1}>
              <Stack direction='row' justifyContent='space-between'>
                {comment.user && <Typography variant='subtitle2'>{formatPerson(comment.user)}</Typography>}
                <Typography variant='body2'>{formatDate(comment.createdAt)}</Typography>
              </Stack>
              <Typography variant='body1'>{comment.content}</Typography>
            </Stack>
          </Paper>
        </Box>
      </Stack>
    </Box>
  )
}
