import { useState } from 'react';
import { Button } from '@mui/material';
import { LeadsList } from 'entities/lead/lead-list';
import AddIcon from '@mui/icons-material/Add';
import { AddLeadDialog } from 'features/lead/create';
import { Heading } from 'shared/ui/list-page-heading';
import { useTitleHook } from 'hooks/page-title.hook';
import { usePagingParams } from 'shared/hooks/paging-params';

const LeadsListPage = () => {
  useTitleHook('Лиды');

  const [isAddLeadDialogOpen, setAddLeadDialogOpen] = useState(false);
  const [paging, setPaging] = usePagingParams();

  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title="Лиды"
        actions={
          <Button variant="contained" startIcon={<AddIcon />} onClick={() => setAddLeadDialogOpen(true)}>
            Добавить
          </Button>
        }
      />

      <LeadsList paging={paging} onChangePaging={setPaging} withCounts={true} />

      <AddLeadDialog open={isAddLeadDialogOpen} onClose={() => setAddLeadDialogOpen(false)} />
    </>
  );
};

export default LeadsListPage;
