import { reportsLinkManager } from 'utils/link-manager';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useTitleHook } from 'hooks/page-title.hook';

import { Link, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import { CashBalance } from 'features/reports/cash-balance';
import { Sales } from 'features/reports/sales';

const tabInfos = [
  {
    path: reportsLinkManager.tabs.cashBalance,
    label: 'Остатки по счетам',
    icon: <AccountBalanceIcon />,
    component: <CashBalance />
  },
  {
    path: reportsLinkManager.tabs.sales,
    label: 'Продажи',
    icon: <MonetizationOnIcon />,
    component: <Sales />
  }
];


const ReportsPage = () => {
  useTitleHook('Отчеты');
  return (
    <>
      <ReportsTabs />

      <Routes>
        <Route element={<Outlet />}>
          {
            tabInfos.map(tab =>
              (<Route path={tab.path} element={tab.component} key={tab.path} />))
          }
        </Route>
      </Routes>
    </>);
};

const ReportsTabs = () => {
  const location = useLocation();

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
      <Tabs value={location.pathname}>
        {tabInfos.map((tab) => (<Tab
          key={tab.path}
          label={tab.label}
          value={`/${reportsLinkManager.baseUrl}/${tab.path}`}
          iconPosition="start"
          icon={tab.icon}
          to={tab.path}
          component={Link} />
        ))}
      </Tabs>
    </Box>
  );
};
export default ReportsPage;
