import { createApi } from '@reduxjs/toolkit/query/react';
import { EventDto, IPagedCollection, CreateEventDto, ToggleEventDto } from '@sr/dto';
import { CollectionParams, createBaseQuery } from 'shared/api/rtk-query';
import { pagingDataToParams } from 'utils/remote-paged-collection.hook';

interface CompanyEventsQuery extends Required<CollectionParams> {
  companyId: number;
}

type AddEventQuery = {
  companyId: number;
  dto: CreateEventDto;
};

export const eventsApi = createApi({
  reducerPath: 'events',
  baseQuery: createBaseQuery('events'),
  tagTypes: ['event-tabs', 'events-company'],
  endpoints: (builder) => {
    return {
      getTabEvents: builder.query<IPagedCollection<EventDto>, Required<CollectionParams> & { tab: string }>({
        query: ({ tab, paging, filter }) => ({
          url: `?tab=${tab}&${filter}`,
          params: paging && pagingDataToParams(paging),
        }),
        providesTags: (result, error, query, meta) => (result ? [{ type: 'event-tabs', id: query.tab }] : ['event-tabs']),
        // 3 methods for adding query result to existing cache
        // more: https://redux-toolkit.js.org/rtk-query/api/createApi#merge
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return endpointName + '__' + queryArgs.tab + '__' + queryArgs.filter;
        },
        merge(
          currentCacheData: IPagedCollection<EventDto>,
          responseData: IPagedCollection<EventDto>,
          otherArgs: {
            arg: CollectionParams;
          },
        ): IPagedCollection<EventDto> {
          if (otherArgs.arg.paging?.page === 0) {
            return responseData;
          }
          return {
            items: [...currentCacheData.items, ...responseData.items],
            totalItemsCount: responseData.totalItemsCount,
          };
        },
        forceRefetch({ currentArg, previousArg }) {
          return !!currentArg?.paging?.page && currentArg?.paging.page > 0;
        },
      }),
      getCompanyEvents: builder.query<IPagedCollection<EventDto>, CompanyEventsQuery>({
        query: ({ companyId, paging, filter }) => ({
          url: `company/${companyId}?${filter}`,
          params: paging && pagingDataToParams(paging),
        }),
        providesTags: (result, error, query, meta) => (result ? [{ type: 'events-company', id: query.companyId }] : ['events-company']),
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return endpointName + '__' + queryArgs.filter;
        },
        merge(
          currentCacheData: IPagedCollection<EventDto>,
          responseData: IPagedCollection<EventDto>,
          otherArgs: {
            arg: CompanyEventsQuery;
          },
        ): IPagedCollection<EventDto> {
          if (otherArgs.arg.paging.page === 0) {
            return responseData;
          }
          return {
            items: [...currentCacheData.items, ...responseData.items],
            totalItemsCount: responseData.totalItemsCount,
          };
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg?.companyId !== previousArg?.companyId || (!!currentArg?.paging.page && currentArg.paging.page > 0);
        },
      }),
      updateEventToggle: builder.mutation<EventDto, { id: number } & ToggleEventDto>({
        query: (event: { id: number } & ToggleEventDto) => ({
          url: `${event.id}`,
          body: { completed: event.completed, important: event.important },
          method: 'PUT',
        }),
        // change only updated element in all caches manually
        async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
          let updateResult;
          try {
            const { data: updatedEvent } = await queryFulfilled;
            const cacheItems = eventsApi?.util?.selectInvalidatedBy(getState(), ['event-tabs', 'events-company']);
            for (const { endpointName, originalArgs } of cacheItems ?? []) {
              if (endpointName === 'getTabEvents') {
                updateResult = dispatch(
                  eventsApi.util?.updateQueryData(endpointName, originalArgs, (state: IPagedCollection<EventDto>) => {
                    state.items = state.items
                      .map((event) => (event.id === updatedEvent.id ? updatedEvent : event))
                      .filter((event) => {
                        if (event.id !== updatedEvent.id) return true;
                        if (originalArgs.filter.includes('isImportantOnly') && !updatedEvent.important) return false;
                        if (originalArgs.filter.includes('isCompletedHidden') && updatedEvent.completed) return false;
                        return true;
                      });
                  }),
                );
              }
              if (endpointName === 'getCompanyEvents') {
                updateResult = dispatch(
                  eventsApi.util?.updateQueryData(endpointName, originalArgs, (state: IPagedCollection<EventDto>) => {
                    state.items = state.items
                      .map((event) => (event.id === updatedEvent.id ? updatedEvent : event))
                      .filter((event) => {
                        if (event.id !== updatedEvent.id) return true;
                        if (originalArgs.filter.includes('isImportantOnly') && !updatedEvent.important) return false;
                        if (originalArgs.filter.includes('isCompletedHidden') && updatedEvent.completed) return false;
                        return true;
                      });
                  }),
                );
              }
            }
          } catch {
            updateResult && updateResult.undo();
          }
        },
      }),
      addEvent: builder.mutation<EventDto, AddEventQuery>({
        query: ({ dto, companyId }: AddEventQuery) => ({
          url: `${companyId}`,
          body: dto,
          method: 'POST',
        }),
        invalidatesTags: (result, error, query) => (result ? [{ type: 'events-company', id: query.companyId }] : ['events-company']),
      }),
    };
  },
});
