import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { salesApi } from '../sales.api';


export const useSalesPeriods = () => {
  const { data, isLoading, error } = salesApi.useGetSalesPeriodsQuery();
  return {
    salesPeriods: data,
    error: extractNestJsErrorMessage(error),
    isLoading
  };
};
