import {
  Button,
  Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { PersonsList } from './Persons/persons-list';
import { AddPersonDialog, FormData } from './Persons/add-person-dialog';

import { useSnack } from 'shared/ui/snack';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useState } from 'react';
import { CompanyEmployeeDto } from '@sr/dto';
import { CardPaperTitled } from 'shared/ui/card-paper';
import { companiesApi } from 'entities/company/api.slice';
import { useCompanyEmployee } from 'entities/company/company-persons.hook';

const CompanyPersons = (props: { companyId: number; }) => {
  const { companyId } = props;

  const [isAddLinkOpen, setAddLinkOpen] = useState(false);

  const { showSuccess, showError } = useSnack();
  const showConfirmDeleteDialog = useConfirmDialog();

  const { collection } = useCompanyEmployee(companyId);
  const [addCompanyEmployee] = companiesApi.useAddCompanyEmployeeMutation();
  const [removeCompanyPerson] = companiesApi.useRemoveCompanyPersonMutation();

  const handleAddClick = () => {
    setAddLinkOpen(true);
  };

  const handleAddLinkClose = () => {
    setAddLinkOpen(false);
  };

  const handleSubmit = async (formData: FormData) => {
    try {
      const newLink = await addCompanyEmployee({
        companyId,
        data: {
          person: {
            firstName: formData.firstName,
            secondName: formData.secondName,
            middleName: formData.middleName
          },
          position: formData.position
        }
      }).unwrap();
      showSuccess('Сотрудник успешно добавлен');
      setAddLinkOpen(false);
    } catch (e:any) {
      showError(`Ошибка добавления сотрудника к фирме: ${e.message}`);
    }
  };

  const handleDeleteLink = async (linkId: CompanyEmployeeDto) => {
    try {
      await showConfirmDeleteDialog({
        content: 'Удалить контакт?'
      });
      await removeCompanyPerson(linkId.id).unwrap();
      showSuccess('Связь успешно удалена');
    } catch (e:any) {
      showError(`Ошибка удаления сотрудника: ${e.message}`);
    }
  };

  return (
    <>
      <CardPaperTitled title="Сотрудники">
        <Box mt={1}>
          <PersonsList list={collection} onLinkDelete={handleDeleteLink} companyId={companyId} />
        </Box>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button size="small" startIcon={<AddIcon />} onClick={handleAddClick}>
            Добавить
          </Button>
        </Box>
      </CardPaperTitled>

      <AddPersonDialog open={isAddLinkOpen} onClose={handleAddLinkClose} submit={handleSubmit} />
    </>
  );
};

export default CompanyPersons;