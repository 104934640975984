import { Link as RouterLink } from 'react-router-dom';
import { Button, ButtonProps, Link as MuiLink } from '@mui/material';
import { ReactNode } from 'react';

type LinkProps = {
  to: string,
  download?: boolean,
  target?: string,
  children: ReactNode,
  color?: string,
}

export const Link = ({ to, children, download, target, color }: LinkProps) => {
  return (
    <MuiLink component={RouterLink} to={to} download={download} target={target} color={color} >
      {children}
    </MuiLink>
  );
}


export const ButtonLink = ({ to, children, download, target, buttonProps }: LinkProps & { buttonProps?: ButtonProps }) => {
  return (
    <Button
      component={RouterLink}
      to={to}
      download={download}
      target={target}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}