import { createApi } from '@reduxjs/toolkit/query/react';
import { CreateLegalEntityTypeDto, LegalEntityTypeBaseInfoDto, LegalEntityTypeDto, UpdateLegalEntityTypeActiveDto, UpdateLegalEntityTypeDto } from '@sr/dto';
import { createBaseQuery } from 'shared/api/rtk-query';

export const legalEntitiesTypeApi = createApi({
  reducerPath: 'legal-entities-type',
  tagTypes: ['legal-entity-type'],
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    getLegalEntitiesType: builder.query({
      query: (paginate) => ({
        url: 'legal-entity-type',
        method: 'GET',
        params: { ...paginate },
      }),
      providesTags: (result, error, query) => [{ type: 'legal-entity-type', id: 'LIST' }],
    }),
    getLegalEntitiesTypeActive: builder.query({
      query: () => ({
        url: 'legal-entity-type/active',
        method: 'GET',
      }),
      providesTags: [{ type: 'legal-entity-type', id: 'LIST' }],
    }),
    getLegalEntityTypeStatusActive: builder.query<LegalEntityTypeDto, number>({
      query: (legalEntityTypeId) => ({
        url: `legal-entity-type/${legalEntityTypeId}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'legal-entity-type', id: 'LIST' }],
    }),

    updateLegalEntityTypeStatusActive: builder.mutation<LegalEntityTypeDto, UpdateLegalEntityTypeActiveDto>({
      query: ({ ...dto }) => ({
        url: 'legal-entity-type',
        body: dto,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, query) => [{ type: 'legal-entity-type', id: 'LIST' }],
    }),

    createLegalEntityType: builder.mutation<LegalEntityTypeDto, CreateLegalEntityTypeDto>({
      query: (dto) => ({
        url: 'legal-entity-type',
        body: dto,
        method: 'POST',
      }),
      invalidatesTags: (result, error, query) => [{ type: 'legal-entity-type', id: 'LIST' }],
    }),

    updateLegalEntityType: builder.mutation<LegalEntityTypeDto, UpdateLegalEntityTypeDto>({
      query: ({ ...dto }) => ({
        url: 'legal-entity-type/update',
        body: dto,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, query) => [{ type: 'legal-entity-type', id: 'LIST' }],
    }),
  }),
});
