import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from 'shared/api/rtk-query';
import { PeriodDto } from '@sr/dto';
import { SALARY_BASE_URL } from 'shared/api/api';

export const salaryApi = createApi({
  reducerPath: 'salary',
  baseQuery: createBaseQuery(SALARY_BASE_URL),
  endpoints: (builder) => ({
    getSalaryPeriods: builder.query<PeriodDto[], unknown>({
      query: () => ({
        url: '/periods',
        method: 'GET',
      }),
      providesTags: ['periods'],
    }),
  }),
  tagTypes: ['periods'],
});
