import { CreateLeadDto, EMPTY_CONTACTS, IContacts } from '@sr/dto';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';
import { toOptionalNumberField } from 'utils/form-helper';

export type FormData = {
  subject: string;
  content: string;
  statusId: number | '';
  budgetId: number | '';
  qualityId: number | '';
  userId: number | '';
  contacts: IContacts;
};

export type BulkFormData = {
  statusId: number | string;
  budgetId: number | string;
  qualityId: number | string;
  userId: number | string;
};

export const initialValues: FormData = {
  subject: '',
  content: '',
  statusId: 1,
  budgetId: '',
  qualityId: '',
  userId: '',
  contacts: EMPTY_CONTACTS,
};

export const toCreateDto = (formData: FormData) => {
  const lead: CreateLeadDto = {
    subject: formData.subject,
    content: formData.content,
    qualityId: toOptionalNumberField(formData.qualityId),
    budgetId: toOptionalNumberField(formData.budgetId),
    userId: Number(formData.userId),
    statusId: Number(formData.statusId),
    contacts: {
      emails: formData.contacts.linkedEmails.map((x) => ({ email: x.email })),
      phones: formData.contacts.linkedPhones.map((x) => ({ phone: x.phone })),
      urls: formData.contacts.linkedUrls.map((x) => ({ url: x.url.url, urlTypeId: x.url.urlType.id })),
    },
  };

  return lead;
};

export const validate = (values: FormData): FormikErrors<FormData> => {
  let validationResult: FormikErrors<FormData> = {};

  if (!values.subject) validationResult.subject = VALIDATION_REQUIRED_FIELD;

  if (!values.content) validationResult.content = VALIDATION_REQUIRED_FIELD;

  if (!values.statusId) validationResult.statusId = VALIDATION_REQUIRED_FIELD;

  // if (!values.budgetId)
  //   validationResult.budgetId = VALIDATION_REQUIRED_FIELD;

  // if (!values.qualityId)
  //   validationResult.qualityId = VALIDATION_REQUIRED_FIELD;

  return validationResult;
};
