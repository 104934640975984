import { Box } from '@mui/material';
import { CardPaper } from '../../../shared/ui/card-paper';
import { ManagerUrlParamsFilter } from '../../../entities/filters/manager-url-params-filter';
import { LeadQualityParamFilter } from './lead-quality-param-filter';
import { DateUrlParamsFilter } from '../../../entities/filters/date-url-params-filter';
import { LeadStatusParamFilter } from './lead-status-param-filter';
import { LeadBudgetParamFilter } from './leads-budget-param-filter';

export const LeadsFilter = () => {
  return (
    <CardPaper
      sx={{
        mb: 2,
        py: 2,
        px: 1,
        '& .MuiBox-root': {
          padding: '0px !important',
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
          paddingTop: '8px',
          paddingBottom: '8px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: { xs: 'wrap', md: 'wrap', lg: 'wrap', xl: 'nowrap' },
          gap: { xs: 2, md: 2, lg: 1, xl: 1 },
          alignItems: 'center',
        }}
      >
        <Box sx={{ flex: { xs: '1 1 100%', sm: '1 1 calc(50% - 8px)', lg: '0.9 1 0' }, minWidth: 180, p: 0 }}>
          <LeadBudgetParamFilter queryParamName={'budgetId'} />
        </Box>
        <Box sx={{ flex: { xs: '1 1 100%', sm: '1 1 calc(50% - 8px)', lg: '0.9 1 0' }, minWidth: 180, p: 0 }}>
          <LeadQualityParamFilter queryParamName={'qualityId'} />
        </Box>
        <Box sx={{ flex: { xs: '1 1 100%', sm: '1 1 calc(100% - 8px)', lg: '1.5 1 0' }, minWidth: 230, p: 0 }}>
          <LeadStatusParamFilter queryParamName={'statusId'} customSize={1} multi={true} />
        </Box>
        <Box sx={{ flex: { xs: '1 1 100%', sm: '1 1 calc(100% - 8px)', lg: '1.3 1 0' }, minWidth: 250, p: 0 }}>
          <ManagerUrlParamsFilter queryParamName="managerId" withNonSelect={true} />
        </Box>
        <Box sx={{ flex: { xs: '1 1 100%', sm: '1 1 calc(50% - 8px)', lg: '1 1 0' }, minWidth: 180, p: 0 }}>
          <DateUrlParamsFilter queryParamName={'fromDate'} label="Создан с" />
        </Box>
        <Box sx={{ flex: { xs: '1 1 100%', sm: '1 1 calc(50% - 8px)', lg: '1 1 0' }, minWidth: 180, p: 0 }}>
          <DateUrlParamsFilter queryParamName={'toDate'} label="Создан по" />
        </Box>
      </Box>
    </CardPaper>
  );
};
