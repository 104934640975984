import { plainToInstance, Type } from 'class-transformer';
import {
  IsBoolean,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsNumberString,
  IsOptional,
  IsString,
  Max,
  MaxLength,
  Min,
  MinLength,
  Validate,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import { Identifiable } from './identifiable';
import { StringifiedBoolean } from './publishing-data.dto';
import { LegalEntityTypeDto } from './legal-entity-type.dto';

@ValidatorConstraint({ name: 'IsEmptyOrNumberString', async: false })
export class IsEmptyOrNumberString implements ValidatorConstraintInterface {
  validate(value: string) {
    return value === '' || (/^\d{9}$/.test(value));
  }

  defaultMessage() {
    return 'КПП должен состоять из цифр (9 цифр)';
  }
}

export class CreateLegalEntityDto {
    @IsNotEmpty()
      name: string;

    @IsNumberString({ no_symbols: true })
      inn: string;

    @IsOptional()
    @Validate(IsEmptyOrNumberString)
      kpp: string;

    // @Type(() => LegalEntityTypeDto)
    // legalEntityTypeId: LegalEntityTypeDto;
    @IsNumber()
      legalEntityTypeId: number;

    @IsString()
      address: string;

    @IsString()
      postAddress: string;

    @IsBoolean()
      generateWcc: boolean;

    @IsOptional()
      vat?: number | null;

    @IsOptional()
    @IsBoolean()
      noVat?: boolean;

}

export class LegalEntityDto extends CreateLegalEntityDto {
  id: number;

    @Type(() => LegalEntityTypeDto)
      legalEntityType: LegalEntityTypeDto;

    isActive: boolean;
    isOurLegalEntity?: boolean;
    canIssueInvoice?: boolean;
    canAcceptInvoice?: boolean;
}

export class UpdateLegalEntityDto extends CreateLegalEntityDto {
  id: number;
}

export class LegalEntityBaseInfoDto {
  id: number;
  name: string;
    @Type(() => LegalEntityTypeDto)
      legalEntityType: LegalEntityTypeDto;
    inn: string;

    @IsOptional()
    @Validate(IsEmptyOrNumberString)
      kpp: string;
    
    isActive: boolean;
    isOurLegalEntity?: boolean;
    canIssueInvoice?: boolean;
    canAcceptInvoice?: boolean;

    static fromPlain(obj: any) { return plainToInstance<LegalEntityBaseInfoDto, any>(LegalEntityBaseInfoDto, obj) }
}

export class LegalEntityOurDataDto extends Identifiable{
  invoicePrefix: string;
  isClientInvoicesEnabled: boolean;
  isSupplierInvoicesEnabled: boolean;
  legalEntityTax: number;
  stamp?: File | null | undefined;
  sellerSignerId: number | null;
}

export class LegalEntityOurDataMultipartFormUpdateDto extends Identifiable {
    @IsString()
    @MaxLength(8)
    @IsNotEmpty()
      invoicePrefix: string;

    @IsString()
    @IsEnum(StringifiedBoolean)
      isClientInvoicesEnabled: StringifiedBoolean;

    @IsString()
    @IsEnum(StringifiedBoolean)
      isSupplierInvoicesEnabled: StringifiedBoolean;

    @IsNumber()
    @Min(0)
    @Max(100)
      legalEntityTax: number;

    stamp?: File | null | undefined;

    sellerSignerId: number | null;
}
export type LegalEntityOurDataMultipartFormCreateDto  = Omit<LegalEntityOurDataMultipartFormUpdateDto, 'id'>

export class LegalEntityActiveStatusDto {
  isActive: boolean;
}

export class LegalEntityFilterDto {
    @IsString()
    @IsOptional()
    @MinLength(2)
    @MaxLength(20)
      search?: string;

    @IsNumber()
    @IsOptional()
      companyId?: number;
}
