import { IsNotEmpty, IsNumber, IsString } from 'class-validator';
import { RegionInfoDto } from '../region/region.dto';

export class CreateCityDto {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsNotEmpty()
  @IsNumber()
  regionId: number;
}

export class CityInfoDto {
  id: number;
  name: string;
  region: RegionInfoDto;
  slug: string;
}
