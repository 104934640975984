import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from 'shared/api/rtk-query';
import {
  CombineCompaniesDto,
  CompanyDto,
  CompanyEmployeeDto,
  CompanyToCombineDto
} from '@sr/dto';

// TODO: однажды надо будет перенѝти вѝе НПИ методы работы ѝ компаниѝми ѝюда
const URL = 'combine-companies';

export const companiesApi = createApi({
  reducerPath: 'companies',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    listCombiningCompanies: builder.query<CompanyToCombineDto[], string>({
      query: (comapnyIdsList) => ({
        url: URL,
        method: 'GET',
        params: { companies: comapnyIdsList }
      })
    }),
    combineCompanies: builder.mutation<CompanyDto, CombineCompaniesDto>({
      query: (query) => ({
        url: URL,
        method: 'POST',
        body: query
      }),
      invalidatesTags: ['Contacts', 'dupes']
    }),

    getCompanyEmployees: builder.query<CompanyEmployeeDto[], number>({
      query: (companyId) => `company-employees/${companyId}/persons`,
      providesTags: ['CompanyEmployees']
    }),

    addCompanyEmployee: builder.mutation<CompanyEmployeeDto, { companyId: number; data: { person: { firstName: string; secondName?: string; middleName?: string }; position?: string } }>({
      query: ({ companyId, data }) => ({
        url: `company-employees/${companyId}/persons`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['CompanyEmployees']
    }),

    removeCompanyPerson: builder.mutation<void, number>({
      query: (personCompanyId) => ({
        url: `company-employees/link/${personCompanyId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['CompanyEmployees']
    })

  }),
  tagTypes: ['Contacts', 'dupes', 'CompanyEmployees']
});
