import { ErrorBanner } from 'shared/ui/error-banner';
import { useSalesPeriods } from './sales-period.hook';
import { Period } from 'entities/period-picker/types';
import { PeriodPicker } from 'entities/period-picker/period-picker';
import { getCurrentPeriod } from 'entities/period-picker/period.helpers';
import { InputSize } from 'shared/layout/constants';
import { ReactNode } from 'react';

interface Props {
  title?: ReactNode;
  selectedPeriod?: Period;
  onSelect: (period: Period) => void;
  size?: InputSize;
  readOnly?: boolean;
  withQueryParams?: boolean;
}

export const SalesPeriodPicker = ({
  size = 'small',
  selectedPeriod = getCurrentPeriod(),
  onSelect,
  readOnly = false,
  withQueryParams = false
}: Props) => {
  const { salesPeriods, isLoading, error } = useSalesPeriods();
  if (error) {
    return <ErrorBanner errorMessage={error} />;
  }

  return (
    <>
    {!isLoading && (
      <PeriodPicker
          size={size}
          periods={salesPeriods}
          onSelect={onSelect}
          selectedPeriod={selectedPeriod}
          isLoading={isLoading}
          withQueryParams={withQueryParams}
          readOnly={readOnly}
        />
    )}
      </>
  );
}