import { UserDto } from './user.dto';
import { IsNotEmpty, IsString, IsArray, IsNumber } from 'class-validator';

export class SalesDepartmentDto {
  id: number;
  title: string;
  employees: UserDto[];
}
export class CreateSalesDepartmentDto {
  @IsString()
  @IsNotEmpty()
  title: string;

  @IsArray()
  employees: number[];
}

export class UpdateSalesDepartmentDto {
  @IsNumber()
  id: number;

  @IsString()
  title: string;

  @IsArray()
  employees: number[];
}
