import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_BASE_URL, SALES_DEPARTMENTS_BASE_URL } from 'shared/api/api';
import { CollectionParams, setAuthHeader } from 'shared/api/rtk-query';
import { CreateSalesDepartmentDto, SalesDepartmentDto, UpdateSalesDepartmentDto } from '@sr/dto';

const SALES_DEPARTMENTS_PATH = SALES_DEPARTMENTS_BASE_URL;

export const salesDepartmentsApi = createApi({
  reducerPath: 'sales-departments',
  baseQuery: fetchBaseQuery({
    baseUrl: APP_BASE_URL,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSalesDepartmentsList: builder.query<SalesDepartmentDto[], CollectionParams>({
      query: () => ({
        url: SALES_DEPARTMENTS_PATH,
        method: 'GET',
      }),
      providesTags: ['sales-departments'],
    }),
    getSalesDepartment: builder.query<SalesDepartmentDto, number>({
      query: (id: number) => ({
        url: `${SALES_DEPARTMENTS_PATH}/${id}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, id) => [{ type: 'sales-department', id }],
    }),
    deleteSalesDepartment: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `${SALES_DEPARTMENTS_PATH}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'sales-department', id }, 'sales-departments'],
    }),
    updateSalesDepartment: builder.mutation<SalesDepartmentDto, UpdateSalesDepartmentDto>({
      query: (department: UpdateSalesDepartmentDto) => ({
        url: `${SALES_DEPARTMENTS_PATH}/${department.id}`,
        body: department,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, department) => [{ type: 'sales-department', id: department.id }, 'sales-departments'],
    }),
    addSalesDepartment: builder.mutation<SalesDepartmentDto, CreateSalesDepartmentDto>({
      query: (dto: CreateSalesDepartmentDto) => ({
        url: SALES_DEPARTMENTS_PATH,
        body: dto,
        method: 'POST',
      }),
      invalidatesTags: ['sales-department', 'sales-departments'],
    }),
  }),
  tagTypes: ['sales-department', 'sales-departments'],
});
