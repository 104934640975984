import { createApi } from '@reduxjs/toolkit/query/react';
import { PublishingDataDto, PublishingDataCreateFormDto, PublishingDataFormDto } from '@sr/dto';
import { createBaseQuery } from 'shared/api/rtk-query';
import { toRtkFormData } from 'utils/to-form-data';

const PUBLISHING_DATA_BASE_PATH = '/publishing-data';

export const publishingDataApi = createApi({
  reducerPath: 'publishing-data',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    getPublishingData: builder.query<PublishingDataDto, number>({
      query: (id: number) => ({
        url: `${PUBLISHING_DATA_BASE_PATH}/${id}`,
        method: 'GET',
      }),
      providesTags: ['*'],
    }),
    addPublishingData: builder.mutation<PublishingDataDto, { urlSuffix: string; data: PublishingDataCreateFormDto }>({
      query: ({ urlSuffix, data }: { urlSuffix: string; data: PublishingDataCreateFormDto }) => ({
        url: `${PUBLISHING_DATA_BASE_PATH}/${urlSuffix}`,
        body: toRtkFormData<PublishingDataCreateFormDto>(data),
        method: 'POST',
      }),
      invalidatesTags: ['*'],
    }),
    updatePublishingData: builder.mutation<PublishingDataDto, PublishingDataFormDto>({
      query: (publishingData: PublishingDataFormDto) => ({
        url: `${PUBLISHING_DATA_BASE_PATH}/${publishingData.id}`,
        body: toRtkFormData<PublishingDataFormDto>(publishingData),
        formData: true,
        method: 'PUT',
      }),
      invalidatesTags: ['*'],
    }),
  }),
  tagTypes: ['*'],
});
