import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from 'shared/api/rtk-query';
import { ContactDupesDto, ContactType } from '@sr/dto';
import { dupesActions } from './store.slice';

const BASE_URL = 'contacts/dupes';

export const contactDupesApi = createApi({
  reducerPath: 'contact-dupes',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    getDupes: builder.query<ContactDupesDto, { contactType: ContactType; contactString: string }>({
      query: ({ contactType, contactString }) => ({
        url: `${BASE_URL}/${contactType}/${encodeURIComponent(contactString)}`,
        method: 'GET',
      }),
      providesTags: (result, error, query, meta) => (result ? [{ type: 'dupes', id: query.contactString }] : ['dupes']),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        dispatch(dupesActions.startDupesCheck());
        try {
          await queryFulfilled;
        } finally {
          dispatch(dupesActions.finishDupesCheck());
        }
      },
    }),
  }),
  tagTypes: ['dupes'],
});
