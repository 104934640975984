export const APP_BASE_URL = '/api/';

export const INVOICES_BASE_URL = 'invoices';
export const INVOICE_REPORT_BASE_URL = 'invoice-report';
export const INVOICE_CLOSING_BASE_URL = 'invoice-closing';
export const WCC_BASE_URL = '/wcc';
export const USERS_BASE_URL = '/users';
export const PRINT_FORMS_BASE_URL = '/print-forms';
export const COMPANY_BASE_URL = 'company';
export const CONTRACTS_BASE_URL = '/contracts';
export const CONTRACTS_APPENDIX_BASE_URL = '/contract-appendix';
export const MEDIA_BASE_URL = '/media';
export const CITIES_BASE_URL = '/cities';
export const LEAD_BASE_URL = '/lead';

export const SALARY_BASE_URL = 'salary';
export const REPORTS_BASE_URL = 'reports';
export const PURCHASE_BASE_URL = 'purchases';
export const LEGAL_ENTITY_BASE_URL = 'legal-entity';
export const PAY_ORDERS_BASE_URL = 'accounting/payorders';
export const ROLES_BASE_URL = '/roles';
export const PERMISSIONS_BASE_URL = '/permissions';
export const SALES_DEPARTMENTS_BASE_URL = '/sales-departments';
