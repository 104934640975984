import { UpdateUserCompanyDataDto, UserCompanyDataDto } from '@sr/dto';
import { FormikErrors, isNaN } from 'formik';
import { toOptionalNumberField } from 'utils/form-helper';

export type FormValues = {
  position: string;
  salesDepartmentId: number;
  baseSalary: number | '';
  salesPlan: number | '';
  supervisorId: number;
  defaultManagerPercent: number | '';
  bonusFundRate: number | '';
};

export const validate = (values: FormValues): FormikErrors<FormValues> => {
  const errors: FormikErrors<FormValues> = {};

  if (isNaN(Number(values.baseSalary))) {
    errors.baseSalary = 'Значение должно быть числом';
  }

  if (values.salesPlan && (isNaN(Number(values.salesPlan)) || !isFinite(Number(values.salesPlan)))) {
    errors.salesPlan = 'Значение должно быть числом';
  }

  const bonusFundRate = Number(values.bonusFundRate);

  if (values.bonusFundRate && isNaN(bonusFundRate) && !isFinite(bonusFundRate)) {
    errors.bonusFundRate = 'Значение должно быть числом';
  } else if (values.bonusFundRate && (bonusFundRate < 0 || bonusFundRate > 100)) {
    errors.bonusFundRate = 'Значение должно быть в диапазоне от 0 до 100';
  }

  const defaultManagerPercent = Number(values.defaultManagerPercent);
  if (isNaN(defaultManagerPercent) || !isFinite(defaultManagerPercent) || defaultManagerPercent < 0 || defaultManagerPercent > 100) {
    errors.defaultManagerPercent = 'Значение должно быть в диапазоне от 0 до 100';
  }

  return errors;
};

export const initialValuesFromDto = (dto?: UserCompanyDataDto): FormValues =>
  dto
    ? { ...dto }
    : {
        baseSalary: '',
        salesPlan: '',
        position: '',
        supervisorId: NaN,
        bonusFundRate: '',
        defaultManagerPercent: '',
        salesDepartmentId: NaN,
      };

export const toDto = (formValues: FormValues): UpdateUserCompanyDataDto => ({
  baseSalary: toOptionalNumberField(formValues.baseSalary),
  salesPlan: toOptionalNumberField(formValues.salesPlan),
  position: formValues.position,
  supervisorId: formValues.supervisorId,
  defaultManagerPercent: toOptionalNumberField(formValues.defaultManagerPercent),
  bonusFundRate: toOptionalNumberField(formValues.bonusFundRate),
  salesDepartmentId: formValues.salesDepartmentId,
});
