import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from 'shared/api/rtk-query';
import { InvoiceClosingDataDto, SetInvoiceClosingPeriodDto, SetManagerPercentDto } from '@sr/dto';
import { INVOICE_CLOSING_BASE_URL } from 'shared/api/api';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { invoiceDataApi } from '../../invoice/details/api';
import { salaryEntriesApi } from '../../salary/salary-entries/salary-entries.api';

async function handleOnQueryStarted(
  { invoiceId }: { invoiceId: number },
  { dispatch, queryFulfilled }: { dispatch: ThunkDispatch<any, any, any>; queryFulfilled: Promise<{ data: InvoiceClosingDataDto }> },
): Promise<void> {
  try {
    const { data: invoiceClosingData } = await queryFulfilled;
    dispatch(
      invoiceCloseApi.util.updateQueryData('getInvoiceClosingDetails', invoiceId, (draft) => {
        Object.assign(draft, invoiceClosingData);
      }),
    );
  } catch (e) {
    console.error('Error on closeInvoiceMutation', e);
  }
}

async function refreshRelatedPages({ dispatch, queryFulfilled }: { dispatch: ThunkDispatch<any, any, any>; queryFulfilled: Promise<{ data: InvoiceClosingDataDto }> }) {
  try {
    const { data: invoiceClosingData } = await queryFulfilled;
    // обновляем статус счета на странице "отчета по счету"
    dispatch(invoiceDataApi.util.invalidateTags([{ type: 'invoice-data', id: invoiceClosingData.invoiceId }]));
    // обновляем начисления/удержания на странице зарплаты сотрудника
    dispatch(
      salaryEntriesApi.util.invalidateTags([
        {
          type: 'salary-entries',
          id: `${invoiceClosingData.period.year}-${invoiceClosingData.period.month}-${invoiceClosingData.managerId}`,
        },
      ]),
    );
  } catch (e) {
    console.error('Error on closeInvoiceMutation', e);
  }
}

export const invoiceCloseApi = createApi({
  reducerPath: 'invoice-closing',
  baseQuery: createBaseQuery(INVOICE_CLOSING_BASE_URL),
  endpoints: (builder) => ({
    getInvoiceClosingDetails: builder.query<InvoiceClosingDataDto, number>({
      query: (invoiceId) => ({
        url: `/${invoiceId}`,
        method: 'GET',
      }),
      providesTags: (result, error, invoiceId) => (result ? [{ type: 'invoice-closing', id: invoiceId }] : ['invoice-closing']),
    }),
    setManagerPercentage: builder.mutation<InvoiceClosingDataDto, SetManagerPercentDto & { invoiceId: number }>({
      query: ({ invoiceId, managerPercent }) => ({
        url: `/set-percentage/${invoiceId}`,
        body: { managerPercent },
        method: 'POST',
      }),
      async onQueryStarted({ invoiceId }, { dispatch, queryFulfilled, getCacheEntry, getState }) {
        await handleOnQueryStarted({ invoiceId }, { dispatch, queryFulfilled });
      },
    }),
    setInvoiceClosingPeriod: builder.mutation<InvoiceClosingDataDto, SetInvoiceClosingPeriodDto & { invoiceId: number }>({
      query: ({ invoiceId, period }) => ({
        url: `/set-period/${invoiceId}`,
        body: { period },
        method: 'POST',
      }),
      async onQueryStarted({ invoiceId }, { dispatch, queryFulfilled, getCacheEntry, getState }) {
        await handleOnQueryStarted({ invoiceId }, { dispatch, queryFulfilled });
      },
    }),
    closeInvoiceMutation: builder.mutation<InvoiceClosingDataDto, { invoiceId: number }>({
      query: ({ invoiceId }) => ({
        url: `/close/${invoiceId}`,
        method: 'POST',
        body: {},
      }),
      async onQueryStarted({ invoiceId }, { dispatch, queryFulfilled, getCacheEntry, getState }) {
        await handleOnQueryStarted({ invoiceId }, { dispatch, queryFulfilled });
        await refreshRelatedPages({ dispatch, queryFulfilled });
      },
    }),
    openInvoiceMutation: builder.mutation<InvoiceClosingDataDto, { invoiceId: number }>({
      query: ({ invoiceId }) => ({
        url: `/open/${invoiceId}`,
        method: 'POST',
        body: {},
      }),
      async onQueryStarted({ invoiceId }, { dispatch, queryFulfilled, getCacheEntry, getState }) {
        await handleOnQueryStarted({ invoiceId }, { dispatch, queryFulfilled });
        await refreshRelatedPages({ dispatch, queryFulfilled });
      },
    }),
  }),
  tagTypes: ['invoice-closing'],
});
