import { createApi } from '@reduxjs/toolkit/query/react';
import { ROLES_BASE_URL } from 'shared/api/api';
import { CollectionParams, createBaseQuery } from 'shared/api/rtk-query';
import { CreateRoleDto, RoleDto, UpdateRoleDto } from '@sr/dto';

const USER_ROLES_PATH = ROLES_BASE_URL;

export const userRolesApi = createApi({
  reducerPath: 'user-roles',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    getUserRolesList: builder.query<RoleDto[], CollectionParams>({
      query: () => ({
        url: USER_ROLES_PATH,
        method: 'GET',
      }),
      providesTags: ['*'],
    }),
    getUserRole: builder.query<RoleDto, number>({
      query: (id: number) => ({
        url: `${USER_ROLES_PATH}/${id}`,
        method: 'GET',
      }),
      providesTags: ['*'],
    }),
    deleteUserRole: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `${USER_ROLES_PATH}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['*'],
    }),
    updateUserRole: builder.mutation<RoleDto, UpdateRoleDto>({
      query: (role: UpdateRoleDto) => ({
        url: `${USER_ROLES_PATH}/${role.id}`,
        body: role,
        method: 'PATCH',
      }),
      invalidatesTags: ['*'],
    }),
    addUserRole: builder.mutation<RoleDto, CreateRoleDto>({
      query: (dto: CreateRoleDto) => ({
        url: USER_ROLES_PATH,
        body: dto,
        method: 'POST',
      }),
      invalidatesTags: ['*'],
    }),
  }),
  tagTypes: ['*'],
});
