import PeopleIcon from '@mui/icons-material/People';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import PublicIcon from '@mui/icons-material/Public';
import MapIcon from '@mui/icons-material/Map';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CalculateIcon from '@mui/icons-material/Calculate';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import InsightsIcon from '@mui/icons-material/Insights';
import { RecentActors, ListAlt, BarChart, PermMedia, Dvr, Shop2 } from '@mui/icons-material';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export type ItemBaseType = {
  title: string;
  to: string;
  icon: React.ReactNode;
};

export type MenuBaseItem = ItemBaseType & { subItems?: ItemBaseType[] };

export type MenuItemType = {
  title: string;
  to?: string;
  icon: React.ReactNode;
  subItems?: MenuBaseItem[];
};

export const menuItems: MenuItemType[] = [
  {
    title: 'Рабочий стол',
    to: '/',
    icon: <DevicesOtherIcon />,
  },
  {
    title: 'Дела',
    to: '/events/all',
    icon: <ListAlt />,
  },
  {
    title: 'Отчеты',
    to: '/reports/cash-balance',
    icon: <BarChart />,
  },
  {
    title: 'Клиенты',
    icon: <BusinessCenterIcon />,
    subItems: [
      { title: 'Список клиентов', to: '/clients/list-client-all', icon: <RecentActors /> },
      { title: 'Договора', to: '/contracts', icon: <ArticleIcon /> },
      { title: 'Клиентские счета', to: '/client-invoices', icon: <MoveToInboxIcon /> },
      { title: 'Лиды', to: '/leads', icon: <InsightsIcon /> },
      { title: 'Заявки', to: '/purchases', icon: <PostAddIcon /> },
    ],
  },
  {
    title: 'Бухгалтерия',
    icon: <CalculateIcon />,
    subItems: [
      { title: 'Платежные поручения', to: '/accounting', icon: <PostAddIcon /> },
      { title: 'Зарплата', to: '/salary/home', icon: <CurrencyRubleIcon /> },
      { title: 'Акты', to: '/wccs/bulk-create', icon: <FactCheckIcon /> },
      { title: 'Входящие счета', to: '/supplier-invoices', icon: <MoveToInboxIcon /> },
    ],
  },
  {
    title: 'Поставщики',
    to: '/suppliers',
    icon: <ShoppingCartIcon />,
  },
  {
    title: 'СМИ и Услуги',
    icon: <LiveTvIcon />,
    subItems: [
      { title: 'Список СМИ', to: '/media-list/list', icon: <Dvr /> },
      { title: 'Отгрузки', to: '/media-list/purchases', icon: <Shop2 /> },
      { title: 'Отправленные медиапланы', to: '/media-list/sent-mediaplans', icon: <MarkunreadMailboxIcon /> },
      { title: 'Медиапланирование', to: '/media-planning', icon: <PermMedia /> },
    ],
  },
  {
    title: 'Настройки',
    icon: <SettingsIcon />,
    subItems: [
      { title: 'Общие', to: '/settings/home', icon: <MiscellaneousServicesIcon /> },
      { title: 'Пользователи', to: '/users', icon: <PeopleIcon /> },
      {
        title: 'География',
        to: '/geography',
        icon: <LocationOnIcon />,
        subItems: [
          {
            title: 'Города',
            to: '/cities',
            icon: <LocationCityIcon />,
          },
          { title: 'Регионы', to: '/regions', icon: <MapIcon /> },
          {
            title: 'Страны',
            to: '/countries',
            icon: <PublicIcon />,
          },
        ],
      },
    ],
  },
];
