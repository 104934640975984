import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCurrentPeriod } from 'entities/period-picker/period.helpers';
import { Period } from 'entities/period-picker/types';

const initialState = {
  value: getCurrentPeriod()
};

const salesPeriodSlice = createSlice({
  name: 'salesPeriod',
  initialState: initialState,
  reducers: {
    setPeriod(state, action: PayloadAction<Period>) {
      state.value = action.payload;
    },
  },
});

export const { reducer: salesPeriodReducer, actions: setPeriodActions } = salesPeriodSlice;

