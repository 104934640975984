export class FormCaptionsVariant {
  id: number;
  name: string;
  contractHeadLineTemplate: string;
  contractBottomDetailsTemplate: string;
}

type LEGAL_ENTITY = 1;
export const LEGAL_ENTITY_TYPE: LEGAL_ENTITY = 1;

type INDIVIDUAL = 2;
export const INDIVIDUAL_TYPE: INDIVIDUAL = 2;

type INDIVIDUAL_ENTREPRENEUR = 3;
export const INDIVIDUAL_ENTREPRENEUR_TYPE: INDIVIDUAL_ENTREPRENEUR = 3;

export const FORM_CAPTIONS_VARIANT: FormCaptionsVariant[] = [
  { 
    'id': LEGAL_ENTITY_TYPE, 'name': 'Юр. лицо', 
    'contractHeadLineTemplate': '{ФОРМА_СОБСТВЕННОСТИ} "{НАЗВАНИЕ}", именуемое в дальнейшем {ЗАКАЗЧИК_ИСПОЛНИТЕЛЬ} в лице {ПОДПИСАНТА_ДОЛЖНОСТЬ} {ПОДПИСАНТА}, действующего', 
    'contractBottomDetailsTemplate': '{ФОРМА_СОБСТВЕННОСТИ} "{НАЗВАНИЕ}"' 
  },
  { 
    'id': INDIVIDUAL_TYPE, 'name': 'Физ. лицо', 
    'contractHeadLineTemplate': '{НАЗВАНИЕ} в дальнейшем {ЗАКАЗЧИК_ИСПОЛНИТЕЛЬ} действует', 
    'contractBottomDetailsTemplate': '{НАЗВАНИЕ}' 
  },
  { 
    'id': INDIVIDUAL_ENTREPRENEUR_TYPE, 'name': 'ИП', 
    'contractHeadLineTemplate': '{ФОРМА_СОБСТВЕННОСТИ} {НАЗВАНИЕ} в дальнейшем {ЗАКАЗЧИК_ИСПОЛНИТЕЛЬ} действует', 
    'contractBottomDetailsTemplate': '{ФОРМА_СОБСТВЕННОСТИ} {НАЗВАНИЕ}' 
  }
];
