import { Step, StepLabel, Stepper } from '@mui/material';
import { ContractStatus, ContractType, CONTRACT_TYPE_CLIENT, getContractStatusKeys, getContractStatuses } from '@sr/dto';

export const ContractStatusStepper = ({ status, contractType = CONTRACT_TYPE_CLIENT }: { status: ContractStatus; contractType: ContractType }) => {
  const steps = getContractStatusKeys(contractType).map((statusKey: ContractStatus) => {
    const statusMap = getContractStatuses(contractType) as Record<ContractStatus, string>;
    return statusMap[statusKey];
  });

  const statusKeys = getContractStatusKeys(contractType);

  const activeStepIndex = statusKeys.findIndex((key) => key === status);

  return (
    <Stepper activeStep={activeStepIndex}>
      {steps.map((label: string) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
