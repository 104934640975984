import { ReactNode } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material';
import { ButtonLink } from '../../shared/ui/link-base';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ErrorBanner } from '../../shared/ui/error-banner';

type DesktopWidgetProps = Readonly<{
  title?: string;
  children: ReactNode;
  navigateLink?: string;
  error?: string;
  subtitle?: string;
}>;
export const DesktopWidget = ({ title, navigateLink, children, error, subtitle }: DesktopWidgetProps) => {
  if (error) {
    return <ErrorBanner errorMessage={error} />;
  }
  return (
    <Accordion defaultExpanded={true} sx={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {title && (
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
        )}
        {subtitle && <Typography variant="body1">{subtitle}</Typography>}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
      {navigateLink && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
          <ButtonLink to={navigateLink} buttonProps={{ size: 'small' }}>
            Перейти к полному списку <KeyboardDoubleArrowRightIcon />
          </ButtonLink>
        </Box>
      )}
    </Accordion>
  );
};
