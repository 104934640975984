import { IPerson } from './person.dto';
import { IsBoolean, IsDateString, IsNumber, IsOptional, IsString, Max, Min } from 'class-validator';
import { Type } from 'class-transformer';
import { RoleDto } from './role.dto';
import { SalesDepartmentDto } from './sales-departments.dto';
import { InvoiceDto } from './invoice.dto';

export class UserDto implements IPerson {
  id: number;
  login: string;
  firstName: string;
  secondName: string;
  middleName: string;
  position: string;
  isActive: boolean;
  isFired: boolean;
  roles: RoleDto[];
}

export class UserBaseDto implements IPerson {
  id: number;
  firstName: string;
  secondName?: string;
  middleName?: string;
  isActive?: boolean;
  isFired?: boolean;
}

export class CreateUserDto {
  login: string;
  password: string;

  firstName: string;
  secondName: string;
  middleName: string;

  roles: number[];
}

export class UpdateUserRolesDto {
  id: number;
  roles: number[];
}

export class UserRolesDto {
  id: number;
  roles: RoleDto[];
}

export class UpdateUserProfileBasicDto {
  @IsNumber()
  id: number;
  @IsString()
  login: string;
  @IsString()
  firstName: string;
  @IsString()
  secondName: string;
  @IsString()
  middleName: string;
}

export class ChangePasswordDto {
  // currentPassword: string;
  newPassword: string;
}

export class UserPersonalDataDto {
  @IsOptional()
  @Type(() => Date)
  @IsDateString()
  dob: Date | null = null;

  @IsString()
  passportData: string;

  @IsString()
  residenceAddress: string;
}

export class UserCompanyDataDto {
  position: string;
  @IsOptional()
  @Type()
  @IsNumber()
  baseSalary: number;
  @IsOptional()
  @Type()
  @IsNumber()
  salesPlan: number;
  salesDepartmentId: number;
  supervisorId: number;
  defaultManagerPercent: number;
  supervisedUsers: UserDto[];
  salesDepartment: SalesDepartmentDto;

  @IsOptional()
  @Min(0)
  @Max(100)
  bonusFundRate: number;
}

export interface UpdateUserCompanyDataDto extends Partial<UserCompanyDataDto> {}

export class UserActiveStatusDto {
  isActive: boolean;
}

export class UserFiredStatusDto {
  @IsBoolean()
  isFired: boolean;
}

export class UserEmploymentStatusDto {
  user: UserBaseDto;  
  supervisor: UserBaseDto;
  invoicesCount: number;
  invoices: InvoiceDto[];
  leadsCount: number;
  eventsCount: number;
  companyCount: number;
}