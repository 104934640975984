import { reportsApi } from '../reports.api';
import { PeriodDto } from '@sr/dto';

export type SalesQuery = {
  year: number,
  month: number,
  managerId: number
};

const injectedApi = reportsApi.injectEndpoints({
  endpoints: (builder) => ({
    getSalesPeriods: builder.query<PeriodDto[], void>({
      query: () => ({
        url: 'sales/periods',
        method: 'GET',
      }),
    }),
    getSales: builder.query({
      query: ({ year, month, managerId }) => 
        `sales?year=${year}&month=${month}&managerId=${managerId}`,
    }),
    }),
});

export const salesApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['sales'],
  endpoints: {},
});