import { CreateSalesDepartmentDto, SalesDepartmentDto, UpdateSalesDepartmentDto, UserDto } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';

export type PopupFormData = {
  title: string;
  employees: UserDto[];
};

export const formTools: FormTools<SalesDepartmentDto, CreateSalesDepartmentDto, UpdateSalesDepartmentDto, PopupFormData> = {
  toUpdateDto: (id, formData) => ({
    id: id,
    title: formData.title,
    employees: formData.employees.map((employee) => employee.id),
  }),
  toCreateDto: (formData) => ({
    title: formData.title,
    employees: formData.employees.map((employee) => employee.id),
  }),
  fromDto: (dto) => ({
    title: dto.title,
    employees: dto.employees,
  }),
  validate: (values: PopupFormData): FormikErrors<PopupFormData> => {
    let validationResult: FormikErrors<PopupFormData> = {};
    if (!values.title) {
      validationResult.title = VALIDATION_REQUIRED_FIELD;
    }

    // if (!values.employees.length) {
    //   validationResult.employees = VALIDATION_REQUIRED_FIELD;
    // }

    return validationResult;
  },
  emptyValues: () => ({
    title: '',
    employees: [],
  }),
};
