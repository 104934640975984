import { Box, Tab, Tabs } from '@mui/material';
import { Link, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import StorageIcon from '@mui/icons-material/Storage';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArticleIcon from '@mui/icons-material/Article';
import OutboxIcon from '@mui/icons-material/Outbox';
import TableViewIcon from '@mui/icons-material/TableView';
import { useTitleHook } from 'hooks/page-title.hook';
import { TimeSheet } from 'features/salary/time-sheet/time-sheet';
import { SalaryEntries } from 'features/salary/salary-entries';
import { salaryLinkManager } from 'utils/link-manager';
import { SalaryPeriodPicker } from 'entities/salary/salary-period/salary-period-picker';
import { setPeriodActions } from 'entities/salary/salary-period/salary-period.slice';
import { useActionCreators, useStateSelector } from '../../shared/store';
import { Period } from 'entities/period-picker/types';
import { CloseInvoices } from '../../features/salary/invoice-closing/close-invoices';
import { SummaryTable } from '../../features/salary/summary-table/summary-table';

const tabInfos = [
  {
    path: salaryLinkManager.tabs.home,
    label: 'Зарплата',
    icon: <StorageIcon />,
    component: <SalaryEntries />,
  },
  {
    path: salaryLinkManager.tabs.closeInvoice,
    label: 'Закрытие счетов',
    icon: <OutboxIcon />,
    component: <CloseInvoices />,
  },
  {
    path: salaryLinkManager.tabs.summary,
    label: 'Сводная',
    icon: <TableViewIcon />,
    component: <SummaryTable />,
  },
  {
    path: salaryLinkManager.tabs.reserveFund,
    label: 'Преимиальный фонд',
    icon: <AccountBalanceIcon />,
    component: <>TO-DO reserve fund</>,
  },
  {
    path: salaryLinkManager.tabs.timeSheet,
    label: 'Табель',
    icon: <ArticleIcon />,
    component: <TimeSheet />,
  },
];

const SalaryPage = () => {
  useTitleHook('Зарплата');
  const location = useLocation();

  const selectedPeriod = useStateSelector((state) => state.salaryPageSelectedPeriod.value);
  const { setPeriod } = useActionCreators(setPeriodActions);
  const onSelect = (period: Period) => {
    setPeriod(period);
  };

  return (
    <>
      <Box sx={{ maxWidth: 700 }}>
        <SalaryPeriodPicker
          title="Период закрытия"
          withQueryParams={true}
          selectedPeriod={selectedPeriod}
          onSelect={onSelect}
          size="small"
          withManager={location.pathname === '/salary/home' || location.pathname === '/salary/close-invoice'}
          withStatus={location.pathname === '/salary/close-invoice'}
        />
      </Box>
      <SalaryTabs />
      
      <Routes>
        <Route element={<Outlet />}>
          {tabInfos.map((tab) => (
            <Route path={tab.path} element={tab.component} key={tab.path} />
          ))}
        </Route>
      </Routes>
    </>
  );
};

const SalaryTabs = () => {
  const location = useLocation();

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
      <Tabs value={location.pathname}>
        {tabInfos.map((tab) => (
          <Tab
            key={tab.path}
            label={tab.label}
            value={`/${salaryLinkManager.baseUrl}/${tab.path}`}
            iconPosition="start"
            icon={tab.icon}
            to={`${tab.path}${location.search}`}
            component={Link}
          />
        ))}
      </Tabs>
    </Box>
  );
};
export default SalaryPage;
