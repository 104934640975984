import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from 'shared/api/rtk-query';
import { IContacts, ILinkedUrl, ILinkedPhone, ILinkedEmail, AddContactDto } from '@sr/dto';
import { AddContactQuery, ContactQuery, DeleteContactQuery } from './contacts.types';
import { buildAddContactUrl, buildGetContactsUrl } from './contact.helpers';

const CONTACTS_BASE_PATH = '/contacts';
type AddContactParams = { query: AddContactQuery; data: AddContactDto };

export const contactsApi = createApi({
  reducerPath: 'contacts',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    getContacts: builder.query<IContacts, ContactQuery>({
      query: (query: ContactQuery) => ({
        url: `${CONTACTS_BASE_PATH}/${buildGetContactsUrl(query)}`,
        method: 'GET',
      }),
      providesTags: (result, error, query, meta) => (result ? [{ type: 'Contacts', id: buildGetContactsUrl(query) }] : ['Contacts']),
    }),
    addContact: builder.mutation<ILinkedPhone | ILinkedEmail | ILinkedUrl, AddContactParams>({
      query: ({ data, query }: AddContactParams) => ({
        url: `${CONTACTS_BASE_PATH}/${buildAddContactUrl(query)}`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { query }) => [{ type: 'Contacts', id: buildGetContactsUrl(query) }],
    }),
    deleteContact: builder.mutation<void, DeleteContactQuery>({
      query: (query: DeleteContactQuery) => ({
        url: `${CONTACTS_BASE_PATH}/${query.contactId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, query) => [{ type: 'Contacts', id: buildGetContactsUrl(query) }],
    }),
  }),
  tagTypes: ['Contacts'],
});
