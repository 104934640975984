import { CreateUserDto } from '@sr/dto';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';

export type FormValues = {
  login: string;
  password1: string;
  password2: string;

  firstName: string;
  secondName: string;
  middleName: string;

  roles: number[];
};

export const initialValues: FormValues = {
  login: '',
  password1: '',
  password2: '',
  firstName: '',
  secondName: '',
  middleName: '',

  roles: [],
};

export const toCreateDto = (formData: FormValues) => {
  const user: CreateUserDto = {
    login: formData.login,
    password: formData.password1,
    firstName: formData.firstName,
    secondName: formData.secondName,
    middleName: formData.middleName,
    roles: formData.roles,
  };

  return user;
};

export const validate = (values: FormValues): FormikErrors<FormValues> => {
  let validationResult: FormikErrors<FormValues> = {};

  if (!values.login) validationResult.login = VALIDATION_REQUIRED_FIELD;

  if (!values.password1) validationResult.password1 = VALIDATION_REQUIRED_FIELD;

  if (!values.password2) validationResult.password2 = VALIDATION_REQUIRED_FIELD;

  if (values.password1 && values.password2 && values.password1 !== values.password2) validationResult.password2 = validationResult.password1 = 'Пароли не совпадают';

  if (!values.firstName) validationResult.firstName = VALIDATION_REQUIRED_FIELD;

  return validationResult;
};
