import { Box, Stack } from '@mui/material';
import { useActionCreators, useStateSelector } from 'shared/store';
import { SalesPeriodPicker } from 'entities/reports/sales/sales-period/sales-period-picker';
import { ManagerUrlParamsFilter } from 'entities/filters/manager-url-params-filter';
import { SalesGrid } from 'entities/reports/sales/sales-grid';
import { useSearchParams } from 'react-router-dom';
import { setPeriodActions } from 'entities/salary/salary-period/salary-period.slice';
import { Period } from 'entities/period-picker/types';
import { useSalesList } from 'entities/reports/sales/sales.hook';

export const Sales = () => {
  const selectedPeriod = useStateSelector((state) => state.salaryPageSelectedPeriod.value);
  const { setPeriod } = useActionCreators(setPeriodActions);
  const onSelect = (period: Period) => {
    setPeriod(period);
  };

  const [searchParams] = useSearchParams();

  const managerIdParam = searchParams.get('managerId');
  const initialYear = Number(searchParams.get('year'));
  const initialMonth = Number(searchParams.get('month'));

  const currentDate = initialYear === 0 ? new Date() : null;
  const year = currentDate ? currentDate.getFullYear() : initialYear;
  const month = currentDate ? currentDate.getMonth() : initialMonth;
  const managerId = Number(managerIdParam);

  const { collection, isLoading, error } = useSalesList({ year, month, managerId });

  return (
    <>
      <Stack sx={{ gap: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '600px', '& .MuiInputBase-root': { backgroundColor: '#fff' } }}>
        <Box sx={{ minWidth: 200, maxWidth: 308, mb: 1 }}>
          <SalesPeriodPicker 
            withQueryParams={true}
            selectedPeriod={selectedPeriod}
            onSelect={onSelect}
          />
        </Box>
        <Box sx={{ minWidth: 200, maxWidth: 308, mb: 1 }}>
          <ManagerUrlParamsFilter queryParamName={'managerId'} withNonSelect={false} label={'Менеджер'}/>
        </Box>
      </Stack>
      <SalesGrid collection={collection} isLoading={isLoading} error={error}/>   
    </>
  );
};