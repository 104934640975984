import { Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MediaPriceInfo from './media-price-info';
import { useEffect, useState } from 'react';
import { fetchMediaPrices, uploadPrice } from 'shared/api/prices';
import { IMediaPriceInfo } from 'interfaces/IMediaPriceInfo';
import AddPriceDialog from 'components/Prices/add-price-dialog';
import { FormData } from 'components/Prices/price-form-data';
import { useSnack } from 'shared/ui/snack';

type Props = {
    mediaId: number;
};

const MediaSuppliersPrices = (props: Props) => {
  const { mediaId } = props;
  const [prices, setPrices] = useState<IMediaPriceInfo[] | null>(null);
  const { showError, showSuccess } = useSnack();

  const [addDialogOpen, setAddDialogOpen] = useState(false);

  useEffect(() => {
    fetchMediaPrices(mediaId)
      .then((result) => {
        setPrices(result);
      });

  }, [mediaId]);

  const handleAddClick = () => {
    setAddDialogOpen(true);
  };

  const handleSubmit = (data: FormData) => {
    if (data.priceFile) {
      return uploadPrice({ companies: data.companies, media: mediaId }, data.priceFile, data.extraFiles
      )
        .then(result => {
          setAddDialogOpen(false);
          fetchMediaPrices(mediaId)
            .then((result) => {
              setPrices(result);
            });
          showSuccess('Прайс успешно загружен');

        }).catch((e) => {
          showError(e.message);
        });
    }
    // fixme: красный экран смерти, когда главный прайс не прикреплен.
    //  Очень плохой юзер-экспириенс
    showError('Нужно прикрепить основной файл');
    return Promise.resolve();
  };

  return (
    <>
      {prices && (
        <Stack direction='column' spacing={1}>
          {prices.map((x, i) => <MediaPriceInfo key={i} priceInfo={x}/>)}
        </Stack>)}
      <Button
        variant="contained"
        startIcon={<AddIcon/>}
        onClick={handleAddClick}
      >
                Добавить прайс
      </Button>

      <AddPriceDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={handleSubmit}/>
    </>
  );
};

export default MediaSuppliersPrices;
