import { PurchaseStatusDto } from '../purchase.dto';

export const PURCHASE_STATUS_DRAFT_ID = 1;
export const PURCHASE_STATUS_NEW_ID = 2;
export const PURCHASE_STATUS_INTRODUCED_ID = 3;
export const PURCHASE_STATUS_AGREED_ID = 4;
export const PURCHASE_STATUS_READY_PAID_ID = 5;
export const PURCHASE_STATUS_PAID_ID = 6;
export const PURCHASE_STATUS_VIRTUAL_ID = 7;
export const DELETABLE_PURCHASES = [PURCHASE_STATUS_DRAFT_ID, PURCHASE_STATUS_NEW_ID];

export const PURCHASE_STATUSES: PurchaseStatusDto[] = [
  { id: PURCHASE_STATUS_DRAFT_ID, 'label': 'Черновик' },
  { id: PURCHASE_STATUS_NEW_ID, 'label': 'Новая' },
  { id: PURCHASE_STATUS_INTRODUCED_ID, 'label': 'Внесена' },
  { id: PURCHASE_STATUS_AGREED_ID, 'label': 'Согласована' },
  { id: PURCHASE_STATUS_READY_PAID_ID, 'label': 'Готова к оплате' },
  { id: PURCHASE_STATUS_PAID_ID, 'label': 'Оплачена' },
  { id: PURCHASE_STATUS_VIRTUAL_ID, 'label': 'Виртуальная' }
];

export const canDeletePurchase = (purchaseStatusId: number) => {
  return DELETABLE_PURCHASES.includes(purchaseStatusId);
};
