export const statusCounter = (statuses: number[], fieldName: string, receivedCounts: Array<Record<string, number>>) => {
  const mergedArray = statuses.map((statusId) => {
    const foundItem = receivedCounts?.find((item) => item[fieldName] === statusId);
    return {
      [fieldName]: statusId,
      count: foundItem ? foundItem.count : 0,
    };
  });
  return [...(receivedCounts ? receivedCounts.filter((item1) => !statuses.includes(item1[fieldName])) : []), ...mergedArray];
};
