import { INVOICE_STATUS_CANCELED, INVOICE_STATUS_CLOSED, INVOICE_STATUS_DEBT, INVOICE_STATUS_NEW, INVOICE_STATUS_PAID, INVOICE_STATUS_PARTIAL_PAID, INVOICE_STATUS_WCC_CREATED, InvoiceStatus } from "../enums/invoice-statuses";

export class TurnoverDto {
  INVOICE_STATUS_CLOSED_COUNT: number;
  INVOICE_STATUS_CLOSED_SUM: number;
  INVOICE_STATUS_DEBT_COUNT: number;
  INVOICE_STATUS_DEBT_SUM: number;
  INVOICE_STATUS_NEW_COUNT: number;
  INVOICE_STATUS_NEW_SUM: number;
  INVOICE_STATUS_PAID_COUNT: number;
  INVOICE_STATUS_PAID_SUM: number;
  INVOICE_STATUS_PARTIAL_PAID_COUNT: number;
  INVOICE_STATUS_PARTIAL_PAID_SUM: number;
  INVOICE_STATUS_WCC_CREATED_COUNT: number;
  INVOICE_STATUS_WCC_CREATED_SUM: number;
  fullName: string;
  isActive: number;
  user_id: number;
}

interface ProfitByStatus {
  [INVOICE_STATUS_NEW]: number,
  [INVOICE_STATUS_PAID]: number,
  [INVOICE_STATUS_PARTIAL_PAID]: number,
  [INVOICE_STATUS_DEBT]: number,
  [INVOICE_STATUS_CLOSED]: number,
  [INVOICE_STATUS_CANCELED]: number,
  [INVOICE_STATUS_WCC_CREATED]: number,
}

export class ProfitDto{
  fullName: string;
  profitByStatus: ProfitByStatus;
  totalProfit: number;
}

export class ReportSaleManagerDto{
  turnover: TurnoverDto[];
  profit: ProfitDto[];
}