import { MouseEvent, useMemo } from 'react';
import MenuParams from 'shared/ui/menu-params';
import { IconButton, TableCell, TableRow, Box, Typography, Chip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { INVOICE_STATUS_DEBT, INVOICE_STATUS_PAID, INVOICE_STATUS_PARTIAL_PAID, INVOICE_STATUSES, InvoiceInfoDto, InvoiceStatus, IPagingData } from '@sr/dto';
import { GridLayout, HeadCellsCollection } from 'shared/ui/grid-layout';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { formatDate } from 'utils/date-format-helpers';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import PriceSpan from 'shared/ui/price-span';
import { InvoiceStatusSpan } from '../status-span/status-span';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import ManagerSpan from 'shared/ui/manager-span';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { WccButton } from 'entities/wcc/wcc-button';
import { InvoiceCloseForm } from '../../../features/invoice/invoice-close/invoice-close.form';
import { calculateInvoiceReportTotals } from '../../invoice-report/lib';
import { statusCounter } from '../../../utils/status-counter-helper';
import { useLastClosedInvoice } from '../invoice.hook';
import { formatPersonPruning } from 'utils/person-format-helpers';
import { InvoiceFilter } from 'features/invoice/invoice-filter/invoice-filter';
import { InvoiceContract } from '../ui/invoice-contract';

type Props = {
  collection: IRemotePagedCollection<InvoiceInfoDto>;
  paging: IPagingData;
  onChangePaging?: (paging: IPagingData) => void;
  onCancel?: (invoice: InvoiceInfoDto) => void;
  withSalaryEntries?: boolean;
  readOnly?: boolean;
  withCounts?: boolean;
  withFilter?: boolean;
};

export const InvoicesList = ({ collection, onCancel, paging, onChangePaging, withSalaryEntries = false, readOnly = false, withCounts = false, withFilter = false }: Props) => {
  const { openMenu, menuData } = useMenu<InvoiceInfoDto>({ cancelHandler: onCancel });
  const columns: HeadCellsCollection = useMemo(() => {
    return withSalaryEntries
      ? ['Дата создания', 'Номер', 'Клиент', 'Наше юрлицо', 'Клиентское юрлицо', 'Статус', 'Менеджер',
        'Последний закрытый счёт', { label: 'Сумма', align: 'right' }, { label: 'Прибыль по заявкам', align: 'right' }, 'Кол-во заявок', 'Период закрытия', 'Ставка', '']
      : ['Дата создания', 'Номер', 'Клиент', 'Наше юрлицо', 'Клиентское юрлицо', 'Статус', 'Менеджер',
        { label: 'Сумма', align: 'right' }, { label: 'Прибыль по заявкам', align: 'right' }, 'Акт', 'Договор', ''];
  }, [withSalaryEntries]);
  // TODO запрос по счетам переделать на RTK Query, as типизация будет уже не нужна
  if (collection.collection.items.length === 0 && !collection.isLoading) {
    return <div>Нет данных для просмотра</div>;
  }
  const statuses = statusCounter([INVOICE_STATUS_DEBT, INVOICE_STATUS_PAID, INVOICE_STATUS_PARTIAL_PAID], 'status', collection.collection.meta as Array<Record<string, number>>);

  return (
    <>
      {withFilter && <InvoiceFilter />}
      {withCounts && collection.collection.meta && (
        <Box display="flex" gap={2} my={2} component="div" alignItems="baseline">
          Отображаются данные со статусами
          {statuses.map((item) => {
            return (
              <Chip
                key={`${item.status} - ${item.count}`}
                label={
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2" component="span">
                      {`${INVOICE_STATUSES[item.status as InvoiceStatus]} - `}
                    </Typography>
                    <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>
                      {item.count}
                    </Typography>
                  </Box>
                }
              />
            );
          })}
        </Box>
      )}
      <GridLayout
        columns={columns}
        items={collection.collection.items}
        isLoading={collection.isLoading}
        error={collection.error}
        noItemsText="Список счетов пуст"
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={collection.collection.totalItemsCount}
        itemRender={(i) => <InvoiceListRow readOnly={readOnly} key={i.id} invoice={i} onMenuClick={openMenu} withSalaryEntries={withSalaryEntries} />}
        footer={(items) => <InvoiceListFooter rows={items} colSpan={withSalaryEntries ? 8 : 7} />}
      />

      <RowMenu menuData={menuData} />
    </>
  );
};

type RowProps = {
  invoice: InvoiceInfoDto;
  onMenuClick: (menuParams: MenuParams<InvoiceInfoDto>) => void;
  readOnly?: boolean;
  withSalaryEntries: boolean;
};

const InvoiceListRow = ({ invoice, onMenuClick, withSalaryEntries, readOnly = false }: RowProps) => {
  const priceTotal = invoice.totals.discountPriceTotal;
  const reportTotals = invoice.report ? calculateInvoiceReportTotals(invoice.report.rows) : undefined;

  const { data } = useLastClosedInvoice(invoice.buyerCompany.id, invoice.id);

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: invoice });
  };

  return (
    <>
      <TableRow
        key={invoice.id}
      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          <LinkToInvoice invoice={invoice}>{formatDate(invoice.startDate)}</LinkToInvoice>
        </TableCell>
        <TableCell>
          <LinkToInvoice invoice={invoice} />
        </TableCell>
        <TableCell>
          <LinkToCompany company={invoice.buyerCompany} />
        </TableCell>
        <TableCell>
          <LinkToLegalEntity legalEntity={invoice.seller} />
        </TableCell>
        <TableCell>
          <LinkToLegalEntity legalEntity={invoice.buyer} />
        </TableCell>
        <TableCell>
          <InvoiceStatusSpan status={invoice.status} size="small" />
        </TableCell>
        <TableCell>
          <ManagerSpan person={invoice.manager} />
        </TableCell>
        {withSalaryEntries && (
          <TableCell>
            {
              data ?
                <>
                  <LinkToInvoice invoice={data} /> <br />
                  {data?.managerPercent}% <br />
                  {`${formatPersonPruning(data.manager)}`}
                </>
                : '-'
            }
          </TableCell>
        )}
        <TableCell align='right'>
          <PriceSpan size="small" price={priceTotal} />
        </TableCell>
        <TableCell align='right'>
          {reportTotals && <PriceSpan size="small" price={reportTotals.totalProfitByPurchases} zeroAsDash />}
        </TableCell>
        {withSalaryEntries && invoice.report ? (
          <InvoiceCloseForm invoiceId={invoice.id} rows={invoice.report.rows} totals={calculateInvoiceReportTotals(invoice.report.rows)} withTableView={true} />
        ) : (
          <>
            <TableCell>
              <WccButton invoiceId={invoice.id} initialWcc={invoice.wcc} />
            </TableCell>
            <TableCell>
              <InvoiceContract contract={invoice.contract}/>
            </TableCell>
            {!readOnly && (
              <TableCell align="right">
                <IconButton edge="end" onClick={handleMenuClick}>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            )}
          </>
        )}
      </TableRow>
    </>
  );
};


type InvoiceListFooterProps = {
  rows: InvoiceInfoDto[]
  colSpan: number
}
const InvoiceListFooter = ({ rows, colSpan }: InvoiceListFooterProps) => {
  const priceTotal = rows.reduce((acc, row) => acc + row.totals.discountPriceTotal, 0);
  const profitTotal = rows.reduce((acc, row) => acc + (row.report ? calculateInvoiceReportTotals(row.report.rows).totalProfitByPurchases : 0), 0);
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align='right'>
        <Typography variant='subtitle1'>Итого:</Typography>
      </TableCell>
      <TableCell align='right'><PriceSpan price={priceTotal} size="small" /></TableCell>
      <TableCell align='right'><PriceSpan price={profitTotal} size="small" zeroAsDash /></TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  );
};
