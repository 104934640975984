import { Switch, FormControlLabel } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useSnack } from 'shared/ui/snack';
import { userFiredStatusApi } from './api';
import FiredDialog from 'components/Companies/Users/fired-dialog';

export const UserFiredStatus = ({ userId }: { userId: number }) => {
  const { data, isFetching, isError } = userFiredStatusApi.useGetUserFiredStatusQuery(userId, { refetchOnMountOrArgChange: 60 });
  const [updateData, { isLoading: isUpdating }] = userFiredStatusApi.useUpdateUserFiredStatusMutation();

  const { showSuccess, showError } = useSnack();

  const [openFiredDialog, setOpenFiredDialog] = useState(false);

  const isFired = data?.isFired !== undefined
    ? data.isFired
    : true;

  if (isError) {
    showError('Ошибка получения статуса работы пользователя');
  }

  const handleChange = (e: ChangeEvent, checked: boolean) => {
    e.preventDefault();
    if(!isFired){
      setOpenFiredDialog(true);
    }
    else
    {
      updateData({ userId, data: { isFired: !checked } })
        .unwrap()
        .then(() => showSuccess('Пользователь успешно принят на работу'))
        .catch(e => showError('Ошибка изменения статуса пользователя', e));
    }
  };

  return (
    <>
      <FormControlLabel
        disabled={isFetching || isUpdating || isError}
        control={
          <Switch checked={!isFired} onChange={handleChange} />
        }
        label="пользователь работает"
      />
      <FiredDialog open={openFiredDialog} userId={userId} onClose={() => { setOpenFiredDialog(false) }}/>
    </>
  );
};