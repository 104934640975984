import { INVOICES_BASE_URL } from 'shared/api/api';
import { invoiceApi } from '../api-slice';
import { purchaseApi } from 'entities/purchase/purchase.api';
import { CopyInvoiceDto, CreateInvoiceDto, InvoiceDto, InvoiceInfoDto, SetInvoiceLegalEntityTaxDto } from '@sr/dto';
import { InvoiceLink } from '../invoice.hook';

const injectedApi = invoiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoice: builder.query<InvoiceDto, number>({
      query: (id: number) => ({
        url: `${INVOICES_BASE_URL}/${id}`,
        method: 'GET',
      }),
    }),
    addInvoice: builder.mutation<InvoiceInfoDto, CreateInvoiceDto & InvoiceLink>({
      query: ({ purchaseId, companyId, ...dto }) => ({
        url: purchaseId ? `${INVOICES_BASE_URL}/for-purchase/${purchaseId}` : companyId ? `${INVOICES_BASE_URL}/for-company/${companyId}` : INVOICES_BASE_URL, // невозможный сценарий. вернет 404
        body: JSON.stringify(dto),
        method: 'POST',
      }),
      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if (query.purchaseId) {
            dispatch(purchaseApi.util.invalidateTags([{ type: 'purchases', id: query.purchaseId }]));
          }
        } catch (e) {
          console.error('RTK Query onQueryStarted error', e);
        }
      },
    }),
    copyInvoice: builder.mutation<CopyInvoiceDto, number>({
      query: (id: number) => ({
        url: `${INVOICES_BASE_URL}/copy`,
        body: { invoiceId: id },
        method: 'POST',
      }),
    }),
    setInvoiceLegalEntityTax: builder.mutation<InvoiceDto, SetInvoiceLegalEntityTaxDto & { invoiceId: number }>({
      query: ({ invoiceId, legalEntityTax }) => ({
        url: `${INVOICES_BASE_URL}/set-tax/${invoiceId}/`,
        method: 'PATCH',
        body: { legalEntityTax },
      }),
    }),
  }),
});

export const invoiceDataApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['invoice-data'],
  endpoints: {
    getInvoice: {
      providesTags: (result, error, arg) => [{ type: 'invoice-data', id: arg }],
    },
    addInvoice: {
      invalidatesTags: (result, error, query) => (result ? [{ type: 'invoice-data', id: result.id }] : [{ type: 'invoice-data' }]),
    },
    copyInvoice: {
      invalidatesTags: (result, error, query) => (result ? [{ type: 'invoice-data', id: result.invoiceId }] : [{ type: 'invoice-data' }]),
    },
    setInvoiceLegalEntityTax: {
      invalidatesTags: (result, error, query) => (result ? [{ type: 'invoice-data', id: result.id }] : [{ type: 'invoice-data' }]),
    },
  },
});
