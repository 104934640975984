import { authorizedRequest } from '../../shared/api/axios';
import { CommentDto, CreateCommentDto } from '@sr/dto';

export const fetchComments = async (id: number, baseUrl: string) => {
  const response = await authorizedRequest<void, CommentDto[]>(`/${baseUrl}/${id}/comments`, 'GET');

  return response.map((r) => CommentDto.fromPlain(r));
};

export const postComment = async (id: number, baseUrl: string, dto: CreateCommentDto) => {
  const response = await authorizedRequest<CreateCommentDto, CommentDto>(`/${baseUrl}/${id}/comments`, 'POST', dto);

  return CommentDto.fromPlain(response);
};
