import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnack } from 'shared/ui/snack';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { invoiceDataApi } from 'entities/invoice/details/api';

export const CopyInvoiceButton = ({ invoiceId }: {invoiceId: number}) => {
  const [update, { isLoading }] = invoiceDataApi.useCopyInvoiceMutation();
  const navigate = useNavigate();
  const { showSuccess, showError } = useSnack();
  const showConfirmDialog = useConfirmDialog();

  const copy = async() => {
    if (isLoading) return;
    try {
      const res = await update(invoiceId).unwrap();
      showSuccess('Счет скопирован');
      navigate('/invoice/' + res.invoiceId);
    } catch (e: unknown) {
      showError('Не удалось создать копию счета', e);
    }
  };
  const handleCopy = () => {
    if (isLoading) return;
    showConfirmDialog({ title: 'Вы уверены?', content: 'Создать копию счета?' }).then(copy);
  }; 

  return <Button variant='contained' onClick={handleCopy} disabled={isLoading}>Копия</Button>;
};
