import {
  Grid,
  Stack
} from '@mui/material';

import { CompanyManagers } from 'widgets/company/linked-managers';
import CompanyPersons from './company-persons';
import CompanyContactsCard from './company-contacts-card';
import CompanyFeed from './company-feed';
import { CompanyDto } from '@sr/dto';

type Props = {
  company: CompanyDto;
};

export const CompanyHome = ({ company }: Props) => {

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Stack direction='column' spacing={2}>
            <CompanyContactsCard companyId={company.id} />
            <CompanyManagers />
            <CompanyPersons companyId={company.id} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={8}>
          <CompanyFeed companyId={company.id} />
        </Grid>
      </Grid>
    </>);
};