import { LegalEntityOurDataDto, LegalEntityOurDataMultipartFormCreateDto, LegalEntityOurDataMultipartFormUpdateDto } from '@sr/dto';
import { legalEntitiesApi } from '../legal-entities.api';
import { LEGAL_ENTITY_BASE_URL } from 'shared/api/api';
import { toRtkFormData } from 'utils/to-form-data';

type LegalEntityLink = {
  legalEntityId: number;
};

export const ourLegalEntitiesApi = legalEntitiesApi.injectEndpoints({
  endpoints: (builder) => ({
    getOurLegalEntityData: builder.query<LegalEntityOurDataDto, number>({
      query: (legalEntityId) => ({
        url: `${LEGAL_ENTITY_BASE_URL}/our-legal-entity-data/${legalEntityId}`,
      }),
      providesTags: (result, error, legalEntityId) => [{ type: 'our-legal-entity', id: legalEntityId }],
    }),
    addOurLegalEntityData: builder.mutation<LegalEntityOurDataDto, LegalEntityOurDataMultipartFormCreateDto & LegalEntityLink>({
      query: ({ legalEntityId, ...dto }) => ({
        url: `${LEGAL_ENTITY_BASE_URL}/our-legal-entity-data/${legalEntityId}`,
        method: 'POST',
        body: toRtkFormData(dto),
      }),
      invalidatesTags: (result, error, query) => [{ type: 'our-legal-entity', id: query.legalEntityId }],
    }),
    updateOurLegalEntityData: builder.mutation<LegalEntityOurDataDto, LegalEntityOurDataMultipartFormUpdateDto & LegalEntityLink>({
      query: ({ legalEntityId, ...dto }) => ({
        url: `${LEGAL_ENTITY_BASE_URL}/our-legal-entity-data/${legalEntityId}`,
        method: 'PUT',
        body: toRtkFormData(dto),
      }),
      invalidatesTags: (result, error, query) => [{ type: 'our-legal-entity', id: query.legalEntityId }],
    }),
  }),
});
