import { Button, Stack } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { CardPaper } from 'shared/ui/card-paper';
import { CityPicker } from 'entities/geo/cities/city-picker';
import { MediaTypePickerField } from 'entities/media/media-type-picker';
import { FormValues, fromMedia, validate, toUpdateMedia, emptyValues } from 'entities/media/media-basic-data/form-data';
import { useMediaBasicData, useUpdataMediaBasicData } from 'entities/media/media-basic-data/update-media.hook';
import { useSnack } from 'shared/ui/snack';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';


type Props = {
  mediaId: number;
}

export const MediaBasicDataCard = ({ mediaId }: Props) => {

  const { media } = useMediaBasicData(mediaId);
  const update = useUpdataMediaBasicData();

  const { showSuccess, showError } = useSnack();

  const handleFormSubmit = async (formValues: FormValues) => {
    const updateData = toUpdateMedia(mediaId, formValues);
    return update(updateData)
      .then(() => showSuccess('Данные СМИ успешно обновлены'))
      .catch((error) => showError(`Ошибка обновления данных о СМИ: ${extractNestJsErrorMessage(error)}`));
  };

  const initialValues = media ? fromMedia(media) : emptyValues;

  return (
    <CardPaper sx={{ p: 2 }}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={validate}
        onSubmit={handleFormSubmit}
      >
        {({ dirty, isSubmitting, isValid }: FormikProps<FormValues>) => {
          return (
            <>
              <Form>
                <Stack direction="column" spacing={2}>
                  <Field
                    name="name"
                    label="Название"
                    component={TextField}
                  />
                  <Field
                    name="mediaTypeId"
                    select
                    label="Тип"
                    component={MediaTypePickerField}
                  />
                  <Field
                    name='cityId'
                    label='Город'
                    component={CityPicker}
                  />
                  <Button type='submit' variant='contained' disabled={!dirty || isSubmitting || !isValid}>
                    Сохранить
                  </Button>
                </Stack >
              </Form>
            </>);
        }}
      </Formik>
    </CardPaper>
  );
};
