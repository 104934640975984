import { Box, Button, DialogActions, Stack } from '@mui/material';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import { initialValues, validate, FormData, toCreateDto } from '../form';
import { Dialog } from 'shared/ui/dialog';
import { UserPickerField } from 'features/user/user-picker/ui';
import { LeadStatusPickerField } from 'shared/ui/formik-fields//lead-status-picker-field';
import { LeadBudgetPickerField } from 'shared/ui/formik-fields//lead-budget-picker-field';
import { LeadQualityPickerField } from 'shared/ui/formik-fields//lead-quality-picker-field';
import { createNewLead } from '../model';
import { useNavigate } from 'react-router-dom';
import { useSnack } from 'shared/ui/snack';
import { IContacts } from '@sr/dto';
import { ContactsListField } from '../../../contacts-form-field';

type Props = {
  open: boolean;
  onClose: () => void;
  contacts?: IContacts;
};

export const AddLeadDialog = ({ open, onClose, contacts }: Props) => {
  const navigate = useNavigate();
  const { showSuccess, showError } = useSnack();

  const handleSubmit = async (values: FormData) => {
    const formValues = values;
    if (contacts) {
      formValues.contacts = contacts;
    }
    createNewLead(formValues)
      .then((response) => {
        showSuccess('Лид успешно добавлен');
        navigate(`/leads/${response.id}`);
      })
      .catch((error) => {
        showError('Ошибка добавления лида: ' + error.message);
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
      {({ submitForm, isSubmitting }) => (
        <Dialog onClose={onClose} open={open} maxWidth="xl">
          <Dialog.Title>Новый лид</Dialog.Title>
          <Dialog.Content>
            <Box sx={{ minWidth: 650 }}>
              <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                <Field name="subject" label="Тема" required fullWidth component={TextField} />
                <Field name="statusId" required fullWidth component={LeadStatusPickerField} />
                <Box>
                  <Stack direction="row" spacing={2}>
                    <Field name="budgetId" component={LeadBudgetPickerField} />
                    <Field name="qualityId" component={LeadQualityPickerField} />
                  </Stack>
                </Box>

                <Field name="userId" label="Кому" fullWidth component={UserPickerField} clearable />
                <Field name="content" label="Текст" required fullWidth multiline rows={4} component={TextField} />
                <Box flexGrow={1}>
                  <Field name="contacts" component={ContactsListField} />
                </Box>
              </Stack>
            </Box>
          </Dialog.Content>
          <DialogActions>
            <Button variant="contained" autoFocus onClick={submitForm} disabled={isSubmitting}>
              {isSubmitting ? 'Подождите...' : 'Сохранить'}
            </Button>
            <Button variant="outlined" onClick={onClose} disabled={isSubmitting}>
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
