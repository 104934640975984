import { createApi } from '@reduxjs/toolkit/query/react';
import { PayOrderDto, UpdatePayOrderDateDto } from '@sr/dto';
import { PAY_ORDERS_BASE_URL } from 'shared/api/api';
import { createBaseQuery } from 'shared/api/rtk-query';

export const payOrdersApi = createApi({
  reducerPath: 'pay-orders',
  tagTypes: ['pay-orders'],
  baseQuery: createBaseQuery(PAY_ORDERS_BASE_URL),
  endpoints: (builder) => ({
    updateDatePayOrder: builder.mutation<PayOrderDto, UpdatePayOrderDateDto>({
      query: ({ ...dto }) => ({
        url: '/update-date',
        body: dto,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, query) => [{ type: 'pay-orders', id: query.id }],
    }),
  }),
});
