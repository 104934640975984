import {
  CommentDto,
  CreateCommentDto,
  CreatePayOrderFromPurchaseDto,
  CreatePurchaseDto,
  IPagingData,
  LinkSupplierDto,
  PayOrderDto,
  PurchaseDto,
  PurchaseInfoDto,
  PurchaseStatusCheckDto,
  UnlinkSupplierDto,
} from '@sr/dto';
import { authorizedRequest, fetchPagedReq } from 'shared/api/axios';

const BASE_URL = '/purchases';

export const fetchPurchases = async (paging: IPagingData, filterString?: string) => {
  const params = new URLSearchParams(filterString);
  const response = await fetchPagedReq<void, PurchaseInfoDto>(`${BASE_URL}?${params.toString()}`, paging);
  return response;
};

export const deletePurchase = async (id: number) => {
  const response = await authorizedRequest<void, void>(`${BASE_URL}/${id}`, 'DELETE');

  return response;
};

export const fetchInvoicePurchases = async (invoiceId: number) => {
  const response = await authorizedRequest<void, PurchaseDto[]>(`${BASE_URL}/invoice/${invoiceId}`, 'GET');
  return response.map((x) => PurchaseDto.fromPlain(x));
};

export const fetchUnlinkedPurchases = async (statusId: number[]) => {
  const response = await authorizedRequest<void, PurchaseDto[]>(`${BASE_URL}/unlinked?statusIds=${statusId}`, 'GET');
  return response.map((x) => PurchaseDto.fromPlain(x));
};

export const postPurchase = async (dto: CreatePurchaseDto) => {
  const response = await authorizedRequest<CreatePurchaseDto, PurchaseInfoDto>(BASE_URL, 'POST', dto);
  return PurchaseDto.fromPlain(response);
};

export const unlinkSupplierPurchase = async (dto: UnlinkSupplierDto) => {
  const response = await authorizedRequest<UnlinkSupplierDto, PurchaseDto>(`${BASE_URL}/unlink`, 'PUT', dto);

  return PurchaseDto.fromPlain(response);
};

export const linkSupplierPurchase = async (dto: LinkSupplierDto) => {
  const response = await authorizedRequest<LinkSupplierDto, PurchaseDto>(`${BASE_URL}/link`, 'PUT', dto);

  return PurchaseDto.fromPlain(response);
};

export const patchPurchaseStatus = async (purchase: PurchaseStatusCheckDto) => {
  const response = authorizedRequest<PurchaseStatusCheckDto, PurchaseInfoDto>(`${BASE_URL}/update-status`, 'POST', purchase);
  return PurchaseDto.fromPlain(response);
};

export const createPayOrderFromPurchase = async (purchaseId: number, dto: CreatePayOrderFromPurchaseDto) => {
  const response = authorizedRequest<CreatePayOrderFromPurchaseDto, PayOrderDto>(`${BASE_URL}/${purchaseId}/pay-order`, 'POST', dto);
  return response;
};
