import { extractNestJsErrorMessage } from '../../../shared/api/rtk-query';
import { salesApi, SalesQuery } from './sales.api';

export const useSalesList = ({ year, month, managerId }: SalesQuery) => {
  const { data, isLoading, error } = salesApi.useGetSalesQuery(
    { year, month, managerId },
    { refetchOnMountOrArgChange: 60 }
  );

  return {
    collection: data ? data : [],
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading
  };
};
