import { CreateLeadDto, LeadBudgetDto, LeadInfoDto, LeadQualityDto } from '@sr/dto';
import { createApi } from '@reduxjs/toolkit/query/react';
import { authorizedRequest, postReq } from 'shared/api/axios';
import { createBaseQuery } from 'shared/api/rtk-query';
import { LEAD_BASE_URL } from 'shared/api/api';

const LEAD_PROPS_BASE_URL = '/lead-props';

export const leadBaseApi = createApi({
  reducerPath: 'lead',
  baseQuery: createBaseQuery(),
  endpoints: () => ({}),
});

export const fetchLeadBudgets = async () => {
  return await authorizedRequest<void, LeadBudgetDto[]>(`${LEAD_PROPS_BASE_URL}/budget`, 'GET');
};

export const fetchLeadQualityDropdown = async () => {
  return await authorizedRequest<void, LeadQualityDto[]>(`${LEAD_PROPS_BASE_URL}/quality-dropdown`, 'GET');
};

export const postLead = async (lead: CreateLeadDto) => postReq(LEAD_BASE_URL, lead, LeadInfoDto);
