export enum CompanyPermissions {
  ACCESS_ANY = 'company.view-any',
  EDIT_METADATA = 'company.edit',
  MERGE = 'company.merge',
  LINK_MANAGER = 'company.manage-links',
}

export enum InvoicePermissions {
  EDIT_INVOICE = 'invoice.edit',
  ACCESS_REPORT = 'invoice.report',
  ANY_CLIENT_INVOICES = 'invoice.view-client-invoices',
  ANY_SUPPLIER_INVOICES = 'invoice.view-supplier-invoices',
}

export enum UserPermissions {
  EDIT_DATA = 'user.edit-data',
  CHANGE_PASSWORD = 'user.change-password',
  EDIT_ROLES = 'user.edit-roles',
}

export enum AccountingPermissions {
  ACCOUNTING_ACCESS = 'accounting.access',
}

export enum MediaPermissions {
  EDIT = 'media.edit',
}

// for future permissions implementations, eg. invoice permissions etc...
enum OtherPermissions {}

export type PermissionScope = CompanyPermissions | InvoicePermissions | UserPermissions | AccountingPermissions | MediaPermissions | OtherPermissions;

export type AuthorizedUser = {
  id: number;
  firstName: string;
  secondName: string;
  middleName: string;
  permissions: PermissionScope[];
};

export type LoginDto = {
  login: string;
  password: string;
};

export type RefreshTokenDto = {
  refreshToken: string;
};

export type LoginResponseDto = {
  authorizedUser: AuthorizedUser;
  accessToken: string;
  refreshToken: string;
};
