import { CountryInfoDto, CreateCountryDto } from '@sr/dto';
import { FormikErrors } from 'formik';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';

export interface FormValues {
  name: string;
}

export const fromCountry = (city: CountryInfoDto): FormValues => {
  return {
    name: city.name.trim(),
  };
};

export const toCountry = (formData: FormValues): CreateCountryDto => {
  const dto = new CreateCountryDto();
  dto.name = formData.name;
  return dto;
};

export const initialValues: FormValues = {
  name: '',
};

export const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.name) errors.name = VALIDATION_REQUIRED_FIELD;

  return errors;
};
