import { MouseEvent } from 'react';
import MenuParams from 'shared/ui/menu-params';
import { TableRow, TableCell, IconButton, Chip, Button, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

import { Link } from 'shared/ui/link-base';
import { CreateSalesDepartmentDto, SalesDepartmentDto, UpdateSalesDepartmentDto } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { useCreateOrEditDialog } from '../../hooks/create-or-edit-dialog.hook';
import { roleLinkManager } from '../../utils/link-manager';
import { useAddSalesDepartment, useDeleteSalesDepartment, useSalesDepartmentsList, useUpdateSalesDepartment } from './sales-departments.hooks';
import { NotificationsConfig, useSnack } from '../../shared/ui/snack';
import { formTools, PopupFormData } from './sales-departments-create-edit.form';
import { FormDialog } from '../../shared/ui/form-dialog';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { UsersMultiPickerField } from '../../shared/ui/formik-fields/users-multi-picker-field';

const columns = ['Отделы продаж', 'Сотрудники', ''];

export const SalesDepartmentsList = () => {
  const { collection, isLoading, error } = useSalesDepartmentsList();

  const addItem = useAddSalesDepartment();
  const updateItem = useUpdateSalesDepartment();
  const removeItem = useDeleteSalesDepartment();

  const notificationsConfig: NotificationsConfig = {
    itemTitleForm1: 'отдел продаж',
    itemTitleForm2: 'отдела продаж',
    gender: 'male',
  };

  const { showFailedDelete } = useSnack(notificationsConfig);
  const { dialogProps, openToAdd, openToEdit, itemToEdit } = useCreateOrEditDialog<SalesDepartmentDto, CreateSalesDepartmentDto, UpdateSalesDepartmentDto, PopupFormData>({
    addHandler: async (dto) => {
      await addItem(dto);
    },
    updateHandler: async (dto) => {
      await updateItem(dto);
    },
    formTools: formTools,
    notificationsConfig,
  });

  const { openMenu, menuData } = useMenu<SalesDepartmentDto>({
    editHandler: openToEdit,
    deleteHandler: async (dto) => {
      try {
        await removeItem(dto);
      } catch (err) {
        showFailedDelete(err as Error);
      }
    },
  });

  return (
    <>
      <Button variant="contained" sx={{ mb: 2 }} onClick={() => openToAdd()} startIcon={<AddIcon />}>
        Добавить
      </Button>
      <GridLayout
        columns={columns}
        items={collection}
        isLoading={isLoading}
        error={error}
        totalItemsCount={collection.length}
        noItemsText="Список отделов продаж пуст"
        itemRender={(i) => <Row key={i.id} item={i} onMenuClick={openMenu} />}
      />
      <FormDialog<PopupFormData>
        {...dialogProps}
        renderTitle={!itemToEdit ? 'Новый отдель продаж' : 'Отдел продаж'}
        renderForm={(props) => (
          <Stack sx={{ width: '500px' }}>
            <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
              <Field {...props} required name="title" component={TextField} label="Название отдела продаж" fullWidth />
              <Field {...props} required name="employees" component={UsersMultiPickerField} fullWidth label={'Сотрудники'} />
            </Stack>
          </Stack>
        )}
      />
      <RowMenu menuData={menuData} />
    </>
  );
};

type RowProps = {
  item: SalesDepartmentDto;
  onMenuClick: (menuParams: MenuParams<SalesDepartmentDto>) => void;
};

export const Row = ({ item, onMenuClick }: RowProps) => {
  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: item });
  };

  return (
    <>
      <TableRow key={item.id}>
        <TableCell>
          <Link to={roleLinkManager.link(item.id)}>{item.title}</Link>
        </TableCell>
        {item.employees && (
          <TableCell>
            {item.employees.map((employee) => (
              <Chip key={employee.id} label={`${employee.firstName} ${employee.secondName}`} sx={{ mx: 1, mb: 1 }} />
            ))}
          </TableCell>
        )}
        <TableCell align="right">
          <IconButton edge="end" onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
