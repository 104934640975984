import { FormDialog } from 'shared/ui/form-dialog';
import { useOurCompany } from 'hooks/portal-settings.hook';
import { validate, FormValues, initialValues } from 'components/Contracts/contract.form';
import { ContractFormFields } from './contract.form-fields';
import { CONTRACT_TYPE_CLIENT, ContractType } from '@sr/dto';

interface DialogProps {
  open: boolean;
  title: string;
  values?: FormValues;
  contractType?: ContractType;
  buyerCompanyId: number;
  ourCompanyId: number;
  submit: (formData: FormValues) => Promise<void>;
  onClose: () => void;
}

export function AddContractDialog(props: DialogProps) {
  const { open, onClose, submit, title, values, buyerCompanyId, contractType = CONTRACT_TYPE_CLIENT } = props;
  const ourCompanyId = useOurCompany();

  return (
    <FormDialog
      renderForm={() => <ContractFormFields ourCompanyId={ourCompanyId} otherCompanyId={buyerCompanyId} contractType={contractType} />}
      renderTitle={title}
      open={open}
      onClose={onClose}
      onSubmit={submit}
      validate={validate}
      validateOnChange={true}
      initialValues={values ? values : { ...initialValues, type: contractType }}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    />
  );
}
