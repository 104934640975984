import { fieldToAutocomplete, AutocompleteProps } from 'formik-mui';
import { IPagingData, UserDto } from '@sr/dto';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useUsersList } from '../../../entities/user/users-list/users-list.hook';

export const UsersMultiPickerField = (props: AutocompleteProps<string | UserDto, true, boolean, boolean>) => {
  const { collection, isLoading } = useUsersList({ itemsPerPage: 100, page: 0 } as IPagingData);

  return (
    <Autocomplete
      {...fieldToAutocomplete(props)}
      options={collection.items}
      loading={isLoading}
      multiple
      getOptionLabel={(option: string | UserDto) => `${(option as UserDto).firstName} ${(option as UserDto).secondName}`}
      isOptionEqualToValue={(option: string | UserDto, value: string | UserDto) => (option as UserDto).id === (value as UserDto).id}
      renderInput={(params) => <TextField {...params} required variant="outlined" label="Сотрудники" placeholder="Выберите сотрудника" />}
    />
  );
};
