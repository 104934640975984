import { createApi } from '@reduxjs/toolkit/query/react';
import { INVOICES_BASE_URL } from 'shared/api/api';
import { createBaseQuery } from 'shared/api/rtk-query';
import { CreateInvoiceWccSupplierDto, InvoiceWccDto, UpdateInvoiceWccSupplierDto, WccDto } from '@sr/dto';
import { toRtkFormData } from 'utils/to-form-data';
// import { UpdatePurchaseClientDto } from './purchase.hook';
// import { toRtkFormData } from 'utils/to-form-data';
// ${INVOICES_BASE_URL}/wcc/supplier/${invoiceId}
export const invoiceWccApi = createApi({
  reducerPath: 'invoiceWcc',
  baseQuery: createBaseQuery(`/${INVOICES_BASE_URL}/wcc`),
  endpoints: (builder) => ({
    getInvoicesSupplierWcc: builder.query<WccDto[], number>({
      query: (id) => ({
        url: `/supplier/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, query) => [{ type: 'invoiceWcc', id: query }],
    }),

    createInvoiceSupplierWcc: builder.mutation<InvoiceWccDto, CreateInvoiceWccSupplierDto>({
      query: (dto) => ({
        url: '/supplier',
        body: dto,
        method: 'POST',
      }),
      invalidatesTags: (result, error, query) => [{ type: 'invoiceWcc', id: query.wccNumber }],
    }),

    dropInvoiceSupplierWcc: builder.mutation<void, number>({
      query: (wccId: number) => ({
        url: `/supplier/${wccId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, query) => [{ type: 'invoiceWcc', id: query }],
    }),

    updateInvoiceSupplierWcc: builder.mutation<InvoiceWccDto, UpdateInvoiceWccSupplierDto>({
      query: (data: UpdateInvoiceWccSupplierDto) => ({
        url: `/supplier/${data.id}`,
        body: toRtkFormData<UpdateInvoiceWccSupplierDto>(data),
        formData: true,
        method: 'PUT',
      }),
      invalidatesTags: (res, error, query) => [{ type: 'invoiceWcc', id: query.id }],
    }),

    // updatePublishingData: builder.mutation<PublishingDataDto, PublishingDataFormDto>({
    //       query: (publishingData: PublishingDataFormDto) => ({
    //         url: `${PUBLISHING_DATA_BASE_PATH}/${publishingData.id}`,
    //         body: toRtkFormData<PublishingDataFormDto>(publishingData),
    //         formData: true,
    //         method: 'PUT',
    //       }),
    //       invalidatesTags: ['*'],
    //     }),
    //   }),

    // updatePurchase: builder.mutation<PurchaseDto, UpdatePurchaseClientDto>({
    //   query: (dto) =>({
    //     url: '/update',
    //     method: 'POST',
    //     body: toRtkFormData<UpdatePurchaseClientDto>(dto),
    //     formData: true
    //   }),
    //   invalidatesTags: (res, error, query) =>[{ type: 'purchases', id: query.id }]
    // }),
    // updatePurchaseStatus: builder.mutation<PurchaseDto, UpdatePurchaseStatusDto>({
    //   query: ({ ...dto }) => ({
    //     url: `/status`,
    //     body: dto,
    //     method: 'PUT',
    //   }),
    //   invalidatesTags: (res, error, query) =>[{ type: 'purchases', id: query.id }]
    // })
  }),
  tagTypes: ['invoiceWcc'],
});

// export const postInvoiceSupplierWcc = async (dto: CreateInvoiceWccSupplierDto) => {
//   return await authorizedRequest<CreateInvoiceWccSupplierDto, InvoiceWccDto>(
//     `${INVOICES_BASE_URL}/wcc/supplier`,
//     'POST',
//     dto
//   );
// };
