import { createApi } from '@reduxjs/toolkit/query/react';
import { CollectionParams, createBaseQuery } from 'shared/api/rtk-query';
import { CreateMediaTypeDto, MediaTypeBaseDataDto, UpdateMediaTypeBaseDataDto } from '@sr/dto';

const MEDIA_TYPE_PATH = '/media-types';

export const mediaTypesApi = createApi({
  reducerPath: 'media-type',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    getMediaTypesList: builder.query<MediaTypeBaseDataDto[], CollectionParams>({
      query: () => ({
        url: MEDIA_TYPE_PATH,
        method: 'GET',
      }),
      providesTags: ['*'],
    }),
    getMediaTypeBaseData: builder.query<MediaTypeBaseDataDto, number>({
      query: (id: number) => ({
        url: `${MEDIA_TYPE_PATH}/${id}`,
        method: 'GET',
      }),
      providesTags: ['*'],
    }),
    deleteMediaType: builder.mutation<void, number>({
      query: (cityId: number) => ({
        url: `${MEDIA_TYPE_PATH}/${cityId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['*'],
    }),
    updateMediaTypeBaseData: builder.mutation<MediaTypeBaseDataDto, UpdateMediaTypeBaseDataDto>({
      query: (mediaType: MediaTypeBaseDataDto) => ({
        url: `${MEDIA_TYPE_PATH}/${mediaType.id}`,
        body: mediaType,
        method: 'PATCH',
      }),
      invalidatesTags: ['*'],
    }),
    addMediaType: builder.mutation<MediaTypeBaseDataDto, CreateMediaTypeDto>({
      query: (dto: CreateMediaTypeDto) => ({
        url: MEDIA_TYPE_PATH,
        body: dto,
        method: 'POST',
      }),
      invalidatesTags: ['*'],
    }),
  }),
  tagTypes: ['*'],
});
