import { fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { IPagingData } from '@sr/dto';
import { clearAuthData, getAccessToken } from './token';
import { APP_BASE_URL } from './api';

export type CollectionParams = {
  paging?: IPagingData;
  filter?: string;
  //orderBy: ....
};

export const REFETCH_TIME_SPAN = 5;

export const setAuthHeader = (headers: Headers) => {
  const token = getAccessToken();
  if (token) headers.set('Authorization', `Bearer ${token}`);
};

// fetchFn for logout user on 401 error
export const fetchFn = async (input: RequestInfo, init?: RequestInit) => {
  const res = await fetch(input, init);
  if (res.status === 401) {
    clearAuthData();
    window.location.reload();
  }
  return res;
};

export const createBaseQuery = (path: string = '') =>
  fetchBaseQuery({
    baseUrl: `${APP_BASE_URL}${path}`,
    fetchFn,
    prepareHeaders: (headers, api) => {
      headers.set('Content-Type', 'application/json');
      setAuthHeader(headers);
      return headers;
    },
  });

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => typeof error === 'object' && error != null && 'status' in error;

export const isErrorWithMessage = (error: unknown): error is { message: string } =>
  typeof error === 'object' && error != null && 'message' in error && typeof (error as any).message === 'string';

/**
 * Достаем текстовое userfriendly описание ошибки для отображения в тостере
 * Формат завязан на RTK baseQuery и на формат ответа от NestJs
 * @param error
 * @returns
 */
export function extractNestJsErrorMessage(error: unknown): string {
  if (isFetchBaseQueryError(error)) {
    if (error.status === 'FETCH_ERROR') return 'Ошибка сети';

    if ('data' in error) return isErrorWithMessage(error.data) ? error.data.message : JSON.stringify(error.data);
  }

  // эта часть для ошибок НЕ из rtk, из axios сразу возвращается такое:
  // {
  //   "statusCode": 500,
  //    "message": "Internal server error"
  // }
  // TODO: когда полностью перееду на RTKQuery, axios можно будет выкинуть
  if (isErrorWithMessage(error)) {
    return error.message;
  }

  return JSON.stringify(error);
}
