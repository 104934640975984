import { createApi } from '@reduxjs/toolkit/query/react';
import { COMPANY_BASE_URL, LEGAL_ENTITY_BASE_URL } from 'shared/api/api';
import { createBaseQuery } from 'shared/api/rtk-query';
import { CreateLegalEntityDto, LegalEntityBaseInfoDto, LegalEntityDto, LegalEntityFilterDto, UpdateLegalEntityDto } from '@sr/dto';

type CompanyLink = {
  companyId: number;
};

export const LEGAL_ENTITY_SEARCH_MIN_LENGTH = 2;
export const LEGAL_ENTITY_SEARCH_MAX_LENGTH = 20;

export const legalEntitiesApi = createApi({
  reducerPath: 'legal-entities',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    getCompanyLegalEntities: builder.query<LegalEntityBaseInfoDto[], LegalEntityFilterDto>({
      query: ({ companyId, search }) => ({
        url: `${LEGAL_ENTITY_BASE_URL}/`,
        method: 'GET',
        params: { companyId, search: search && search.length >= LEGAL_ENTITY_SEARCH_MIN_LENGTH ? search : undefined },
      }),
      providesTags: (result, error, query) =>
        result ? [{ type: 'company-legal-entities', id: query.companyId ? String(query.companyId) + query.search : query.search }] : ['company-legal-entities'],
    }),
    addCompanyLegalEntity: builder.mutation<LegalEntityDto, CreateLegalEntityDto & CompanyLink>({
      query: ({ companyId, ...dto }) => ({
        url: `${COMPANY_BASE_URL}/${companyId}/legal-entities`,
        body: dto,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        {
          type: 'company-legal-entities',
          id: arg.companyId,
        },
      ],
    }),
    getLegalEntity: builder.query<LegalEntityDto, number>({
      query: (legalEntityId) => ({
        url: `${LEGAL_ENTITY_BASE_URL}/${legalEntityId}`,
        method: 'GET',
      }),
      providesTags: (result, error, id, meta) => (result ? [{ type: 'legal-entity', id: id }] : ['legal-entity']),
    }),
    updateLegalEntity: builder.mutation<LegalEntityDto, UpdateLegalEntityDto>({
      query: (dto) => ({
        url: `${LEGAL_ENTITY_BASE_URL}`,
        body: dto,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, query, meta) => [{ type: 'legal-entity', id: query.id }, { type: 'our-legal-entity', id: 'LIST' }, { type: 'company-legal-entities' }],
    }),
  }),
  tagTypes: ['legal-entity', 'company-legal-entities', 'our-legal-entity'],
});
