import { createApi } from '@reduxjs/toolkit/query/react';
import { INVOICE_CLOSING_BASE_URL } from 'shared/api/api';
import { createBaseQuery } from 'shared/api/rtk-query';
import { InvoiceDto } from '@sr/dto';

export const invoiceApi = createApi({
  reducerPath: 'invoice-api',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    getLastClosedInvoice: builder.query<InvoiceDto, { companyId: number; id: number }>({
      query: ({ companyId, id }) => ({
        url: `${INVOICE_CLOSING_BASE_URL}/last-closed-invoice/${companyId}/${id}`,
        method: 'GET',
      }),
    }),
  }),
});
