import { TableCell, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { BankAccountType, BA_TYPES, PayOrderDto, PayOrderStatus, POS_STATUSES, POS_DRAFT, POS_PAID } from '@sr/dto';
import { useContext, useState } from 'react';
import { GridLayout } from 'shared/ui/grid-layout';
import { LinkToPayOrder } from 'shared/ui/links/link-to-payorder';
import PriceSpan from 'shared/ui/price-span';
import { formatDate } from 'utils/date-format-helpers';
import { IRemoteCollection } from 'utils/remote-collection.hook';
import { useSnack } from 'shared/ui/snack';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { payOrdersApi } from 'entities/pay-orders/pay-orders.api';
import DeleteIcon from '@mui/icons-material/Delete';
import { deletePayOrder } from 'shared/api/payorders';
import { notificationsConfig } from 'components/PayOrders/pay-orders-list';
import { PurchseDetailPageContext } from 'pages/purchases/purchase-details-page';

type Props = {
  referenceInvoiceId: number;
  payOrders: IRemoteCollection<PayOrderDto>;
};


export const RelatedPayorders = ({ referenceInvoiceId, payOrders }: Props) => {
  const handlePayOrderDeleted = () => {
    payOrders?.reload?.();
  };

  return (
    <>
      <GridLayout<PayOrderDto>
        columns={['Дата', 'Номер', 'Статус', 'Тип', 'Группа', 'Сумма', '']}
        items={payOrders.items}
        isLoading={payOrders.isLoading}
        error={payOrders.error}
        itemRender={payOrder => (
          <PayOrderRow 
            key={payOrder.id} 
            payOrder={payOrder} 
            onDeleted={handlePayOrderDeleted}
          />
        )}
        noItemsText='Нет платежных поручений'
        footer={(items) => (
          <TableRow>
            <TableCell colSpan={5} align='right'>Итого:</TableCell>
            <TableCell>
              <PriceSpan 
                size='small' 
                price={items.reduce((sum, po) => sum + po.rows.reduce((s, r) => s + r.amount, 0), 0)} 
              />
            </TableCell>
          </TableRow>
        )}
      />
    </>
  );
};

const PayOrderRow = ({ 
  payOrder, 
  onDeleted 
}: { 
  payOrder: PayOrderDto;
  onDeleted: () => void;
}) => {
  const totalPaymentAmount = payOrder.rows.reduce((prev, curr) => prev + curr.amount, 0);
  const { showSuccess, showError } = useSnack();
  const showConfirm = useConfirmDialog();
  const { showSuccessDelete, showFailedDelete } = useSnack(notificationsConfig);

  const context = useContext(PurchseDetailPageContext);

  const [date, setDate] = useState<Date | null>(payOrder.paymentDate);
  const [updateDate] = payOrdersApi.useUpdateDatePayOrderMutation();

  const handleRowDelete = () => {
    showConfirm({
      content: 'Удалить платежное поручение?'
    })
      .then(async() => {
        await deletePayOrder(payOrder.id).then(()=>{
          showSuccessDelete();
          onDeleted();
          context?.setReload(!context.reload);    
        });
      })
      .catch(e => showFailedDelete(e));
  };

  const handleDateChange = async (newValue: Date | null) => {
    const newDate = newValue ?? new Date();
    setDate(newDate);
    
    await updateDate({ id: payOrder.id, paymentDate: newValue }).unwrap().then(() => 
    {
      showSuccess('Дата платежного поручения обновлена');
    }).catch((e) => showError(`Ошибка записи: ${e.date.message}`));
  };

  return (
    <TableRow>
      <TableCell>
        {payOrder.status !== POS_DRAFT ? (
          <LinkToPayOrder payorder={payOrder}>
            {formatDate(payOrder.paymentDate)}
          </LinkToPayOrder>
        ) : (
          <DatePicker 
            label="Дата ПП"
            value={payOrder.paymentDate} 
            onChange={handleDateChange}
            views={['year', 'month', 'day']}
            disablePast
          />
        )}
      </TableCell>
      <TableCell>
        <LinkToPayOrder payorder={payOrder}>
          {payOrder.documentNumber}
        </LinkToPayOrder>
      </TableCell>
      <TableCell>
        {POS_STATUSES[payOrder.status as PayOrderStatus]}
      </TableCell>
      <TableCell>
        {BA_TYPES[payOrder.bankAccount.type as BankAccountType]}
      </TableCell>
      <TableCell>
        {payOrder.cashflow.title}
      </TableCell>
      <TableCell>
        <PriceSpan size='small' price={totalPaymentAmount} />
      </TableCell>
      <TableCell>
        <IconButton 
          edge="end" 
          onClick={handleRowDelete} 
          disabled={payOrder.status >= POS_PAID}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
