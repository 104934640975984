import { Chip } from '@mui/material';
import { CONTRACT_STATUS_CONFIRMED, CONTRACT_STATUS_ORIGINAL, CONTRACT_STATUS_READY, CONTRACT_STATUS_SEND, CONTRACT_STATUS_UPLOADED, CONTRACT_STATUSES, ContractStatus } from '@sr/dto';
import DoneIcon from '@mui/icons-material/Done';
import { ReactElement, ReactNode } from 'react';

type AppearanceParams = {
  spanColor: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default',
  spanVariant: 'filled' | 'outlined',
  buttonColor: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit',
  buttonVariant: 'text' | 'outlined' | 'contained',
  icon?: ReactElement,
}
export const CONTRACT_STATUS_STYLE: Record<ContractStatus, AppearanceParams> =
  {
    [CONTRACT_STATUS_READY]: {
      spanColor: 'default',
      spanVariant: 'outlined',
      buttonColor: 'inherit',
      buttonVariant: 'outlined'
    },
    [CONTRACT_STATUS_SEND]: {
      spanColor: 'primary',
      spanVariant: 'outlined',
      buttonColor: 'primary',
      buttonVariant: 'outlined',
      icon: <DoneIcon />,
    },
    [CONTRACT_STATUS_UPLOADED]: {
      spanColor: 'warning',
      spanVariant: 'filled',
      buttonColor: 'warning',
      buttonVariant: 'contained',
    },
    [CONTRACT_STATUS_CONFIRMED]: {
      spanColor: 'success',
      spanVariant: 'outlined',
      buttonColor: 'success',
      buttonVariant: 'outlined',
    },
    [CONTRACT_STATUS_ORIGINAL]: {
      spanColor: 'success',
      spanVariant: 'filled',
      buttonColor: 'success',
      buttonVariant: 'contained',
    },
  };

type Props = {
  status: ContractStatus,
  size?: 'small' | 'medium',
  children?: ReactNode;
}

export const ContractStatusSpan = ({ status, size = 'medium' }: Props) => {
  const params = CONTRACT_STATUS_STYLE[status];
  return (
    <Chip
      label={CONTRACT_STATUSES[status]}
      size={size}
      color={params.spanColor}
    />
    
  );
};
