import { extractNestJsErrorMessage, REFETCH_TIME_SPAN } from 'shared/api/rtk-query';
import { purchaseApi } from './purchase.api';
import { UpdatePurchaseAbstractDto } from '@sr/dto';

export class UpdatePurchaseClientDto extends UpdatePurchaseAbstractDto{
  bankDetailsScan?: File | null ;
  invoiceScan?: File | null;
  wccScan?: File | null;
}

export const usePurchase = (id: number) => {
  const { data, isLoading, error, refetch } = purchaseApi.useGetPurchaseQuery(id,  { refetchOnMountOrArgChange: REFETCH_TIME_SPAN });
  const [ update ] = purchaseApi.useUpdatePurchaseMutation();

  return {
    purchase: data,
    updatePurchase: async (dto: UpdatePurchaseClientDto) => {
      return update(dto).unwrap();
    },
    reload: () => refetch(),
    error: extractNestJsErrorMessage(error),
    isLoading
  };
};